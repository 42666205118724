import React, { useCallback } from "react";
import { BaseFlow } from "@/components/admin/storyGraph/common/BaseFlow";
import {
  MOMENT_NODE_TYPES,
  MomentEdge,
  MomentNode,
  MomentType,
  SceneNode,
} from "@/components/admin/storyGraph/common/types";
import MomentSidebar from "@/components/admin/storyGraph/momentLevel/MomentSidebar";
import { ViewState } from "@/components/admin/storyGraph/StoryGraphContainer";
import { useStoryGraph } from "@/components/admin/storyGraph/common/useGraphData.ts";
import PropertyPanel from "@/components/admin/storyGraph/common/PropertyPanel.tsx";
import MomentDetail from "@/pages/admin/MomentDetail.tsx";
import GenerateMomentsModal from "@/components/admin/generateMomentsModal/GenerateMomentsModal.tsx";
import { modalMomentsGenerateState } from "@/states/ModalState.ts";
import { useRecoilState } from "recoil";
import {
  getMomentsPathToNode,
  getScenesPathToNode,
} from "@/components/admin/storyGraph/util/reactFlowUtil.ts";

interface MomentFlowProps {
  currentView: ViewState;
  setCurrentView: (view: ViewState) => void;
  storyId: string;
  selectedSceneNode: SceneNode | null;
  setSelectedSceneNode: React.Dispatch<React.SetStateAction<SceneNode | null>>;
}

export const MomentFlowUI: React.FC<MomentFlowProps> = ({
  storyId,
  currentView,
  setCurrentView,
  selectedSceneNode,
  setSelectedSceneNode,
}) => {
  const {
    story,
    branchTransitions,
    branches,
    beats,
    beatsheets,
    characters,
    sceneEdges,
    sceneNodes,
    // Moment specific data and handlers
    moments,
    transitions,
    momentNodes,
    momentEdges,
    selectedMomentNode,
    setSelectedMomentNode,
    onMomentNodesChange,
    onMomentEdgesChange,
    onMomentConnect,
    onMomentsDrop,
    onMomentNodeDelete,
    onMomentEdgeDelete,
    isLoading,
    fetchingErrorMessage,
    refreshData,
  } = useStoryGraph(storyId, selectedSceneNode);

  const [isModalMomentsGenerateOpen, setIsModalMomentsGenerateOpen] =
    useRecoilState(modalMomentsGenerateState);

  const onNodeClick = useCallback(
    (_: React.MouseEvent, node: MomentNode) => {
      setSelectedMomentNode(node);
      if (!node.data.isInitialized) {
        setIsModalMomentsGenerateOpen(true);
      }
    },
    [setSelectedMomentNode],
  );

  const onDragStart = (event: React.DragEvent<HTMLDivElement>, nodeType: string) => {
    console.log(event, nodeType);
    event.dataTransfer.setData("application/reactflow", nodeType);
    event.dataTransfer.effectAllowed = "move";
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (fetchingErrorMessage) {
    return <div>Error: {fetchingErrorMessage}</div>;
  }

  return (
    <>
      <BaseFlow<MomentNode, MomentEdge>
        nodes={momentNodes}
        edges={momentEdges}
        onNodesChange={onMomentNodesChange}
        onEdgesChange={onMomentEdgesChange}
        onConnect={onMomentConnect}
        onNodesDelete={onMomentNodeDelete}
        onEdgesDelete={onMomentEdgeDelete}
        onDrop={onMomentsDrop}
        onLayout={() => {
          console.log("onLayout");
        }}
        nodeTypes={MOMENT_NODE_TYPES}
        onNodeClick={onNodeClick}
        sidebar={
          <MomentSidebar
            onDragStart={onDragStart}
            currentView={currentView}
            setCurrentView={setCurrentView}
            setSelectedSceneNode={setSelectedSceneNode}
          />
        }
      />
      <PropertyPanel selectedNode={selectedMomentNode} onClose={() => setSelectedMomentNode(null)}>
        {selectedMomentNode && (
          <MomentDetail
            momentId={selectedMomentNode?.id}
            onMomentUpdate={() => {
              refreshData();
              setSelectedMomentNode(null);
            }}
          />
        )}
      </PropertyPanel>
      {isModalMomentsGenerateOpen && (
        <GenerateMomentsModal
          story={story}
          beats={beats}
          beatsheets={beatsheets}
          branches={branches}
          characters={characters}
          scene={currentView.scene}
          sceneBranch={branches.find((branch) => branch.id === currentView.scene?.branch_id)}
          sceneBeatsheet={beatsheets.find(
            (beatsheet) => beatsheet.id === currentView.scene?.beatsheet_id,
          )}
          branchTransitions={branchTransitions}
          previousScenes={getScenesPathToNode(sceneNodes, sceneEdges, currentView.scene?.id)}
          onCloseCallback={refreshData}
          allMoments={moments}
          allMomentTransitions={transitions}
          previousMomentsInScene={getMomentsPathToNode(
            momentNodes,
            momentEdges,
            selectedMomentNode?.id,
          )}
          momentType={selectedMomentNode?.type as MomentType}
        />
      )}
    </>
  );
};

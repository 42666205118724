import { useCallback } from "react";
import { StoryGraphNode } from "../common/types";
import { Connection, Edge, XYPosition } from "@xyflow/react";

interface ConnectionHandlingProps<TEdge extends Edge> {
  nodes: StoryGraphNode[];
  edges: TEdge[];
  setEdges: React.Dispatch<React.SetStateAction<TEdge[]>>;
  createEmptyEdge: (source: string, target: string) => TEdge;
}

export const useConnectionHandling = <TEdge extends Edge>({
  nodes,
  edges,
  setEdges,
  createEmptyEdge,
}: ConnectionHandlingProps<TEdge>) => {
  const onConnect = useCallback(
    (params: Connection) => {
      setEdges((eds: TEdge[]) => {
        const newEdge = createEmptyEdge(params.source!, params.target!);
        return [...eds, newEdge];
      });
    },
    [setEdges, createEmptyEdge],
  );

  const createEdgeFromLastNode = useCallback(
    (newNodeId: string) => {
      if (nodes.length === 0) return [];
      const lastNode = nodes[nodes.length - 1];
      return [createEmptyEdge(lastNode.id, newNodeId)];
    },
    [nodes, createEmptyEdge],
  );

  const findNodeAtPosition = useCallback(
    (position: XYPosition) => {
      return nodes.find((node) => {
        const nodeWidth = 160;
        const nodeHeight = 60;
        return (
          position.x >= node.position.x &&
          position.x <= node.position.x + nodeWidth &&
          position.y >= node.position.y &&
          position.y <= node.position.y + nodeHeight
        );
      });
    },
    [nodes],
  );

  const handleNodeInsertion = useCallback(
    (targetNode: StoryGraphNode | undefined, newNodeId: string) => {
      if (!targetNode) {
        return [...edges, ...createEdgeFromLastNode(newNodeId)];
      }

      const existingEdge = edges.find((edge) => edge.source === targetNode.id);
      if (!existingEdge) {
        return [...edges, createEmptyEdge(targetNode.id, newNodeId)];
      }

      // Remove existing edge and create two new edges
      const updatedEdges = edges.filter((edge) => edge.id !== existingEdge.id);
      return [
        ...updatedEdges,
        createEmptyEdge(targetNode.id, newNodeId),
        createEmptyEdge(newNodeId, existingEdge.target),
      ];
    },
    [edges, createEdgeFromLastNode, createEmptyEdge],
  );

  return {
    onConnect,
    createEdgeFromLastNode,
    findNodeAtPosition,
    handleNodeInsertion,
  };
};

import { useCallback, useEffect, useMemo } from "react";
import { Connection, Edge, Node } from "@xyflow/react";

import { useStoryFlow } from "@/components/admin/storyGraph/common/useFlow.tsx";
import {
  useFetchBeats,
  useFetchBeatsheets,
  useFetchBranches,
  useFetchBranchTransition,
  useFetchCharacters,
  useFetchMoments,
  useFetchScenes,
  useFetchStory,
  useFetchTransitions,
  useUpdateScene,
} from "@/components/admin/storyGraph/database/useDatabase.tsx";
import { SceneNode } from "@/components/admin/storyGraph/common/types.ts";

export const useStoryGraph = (storyId: string | undefined, selectedSceneNode: SceneNode | null) => {
  const { fetchStory, story, error: storyError, isLoading: storyLoading } = useFetchStory();
  const {
    fetchMoments,
    moments,
    error: momentsError,
    isLoading: momentsLoading,
  } = useFetchMoments();
  const {
    fetchTransitions,
    transitions,
    error: transitionsError,
    isLoading: transitionsLoading,
  } = useFetchTransitions();
  const { fetchScenes, scenes, error: scenesError, isLoading: scenesLoading } = useFetchScenes();
  const { fetchBeats, beats, error: beatsError, isLoading: beatsLoading } = useFetchBeats();
  const {
    fetchBeatsheets,
    beatsheets,
    error: beatsheetsError,
    isLoading: beatsheetsLoading,
  } = useFetchBeatsheets();
  const {
    fetchBranches,
    branches,
    error: branchesError,
    isLoading: branchesLoading,
  } = useFetchBranches();
  const {
    fetchBranchTransitions,
    branchTransitions,
    error: branchTransitionError,
    isLoading: branchTransitionLoading,
  } = useFetchBranchTransition();
  const {
    fetchCharacters,
    characters,
    error: charactersError,
    isLoading: charactersLoading,
  } = useFetchCharacters();

  const { updateScene } = useUpdateScene();

  const fetchingErrorMessage =
    storyError ||
    momentsError ||
    transitionsError ||
    scenesError ||
    beatsError ||
    beatsheetsError ||
    branchesError ||
    branchTransitionError ||
    charactersError;

  const isLoading =
    storyLoading ||
    momentsLoading ||
    transitionsLoading ||
    scenesLoading ||
    beatsLoading ||
    beatsheetsLoading ||
    branchesLoading ||
    branchTransitionLoading ||
    charactersLoading;

  const refreshData = useCallback(() => {
    if (storyId) {
      fetchMoments(storyId);
      fetchTransitions(storyId);
      fetchScenes(storyId);
      fetchBeats(storyId);
      fetchBeatsheets(storyId);
      fetchBranches(storyId);
      fetchBranchTransitions(storyId);
      fetchCharacters(storyId);
    }
  }, [
    storyId,
    fetchMoments,
    fetchTransitions,
    fetchScenes,
    fetchBeats,
    fetchBeatsheets,
    fetchBranches,
    fetchBranchTransitions,
    fetchCharacters,
  ]);

  const storyFlowData = useMemo(
    () => ({
      moments,
      transitions,
      scenes,
      beats,
      beatsheets,
      branches,
      characters,
      selectedSceneNode,
    }),
    [moments, transitions, scenes, beats, beatsheets, branches, characters],
  );

  const {
    // Scene level
    sceneNodes,
    sceneEdges,
    onSceneNodesChange,
    onSceneEdgesChange,
    onScenesDrop,
    onScenesLayout,
    updateSceneNode,

    // Moment level
    momentNodes,
    momentEdges,
    selectedMomentNode,
    setSelectedMomentNode,
    onMomentNodesChange,
    onMomentEdgesChange,
    onMomentsDrop,

    updateMomentNode,
  } = useStoryFlow(storyFlowData);

  useEffect(() => {
    if (storyId) {
      fetchStory(storyId);
      fetchMoments(storyId);
      fetchTransitions(storyId);
      fetchScenes(storyId);
      fetchBeats(storyId);
      fetchBeatsheets(storyId);
      fetchBranches(storyId);
      fetchCharacters(storyId);
    }
  }, [storyId]);

  const onSceneConnect = useCallback((params: Connection) => {
    // Basic connection validation and creation for scenes
    console.log("Scene connection requested:", params);
    // TODO: Implement scene connection logic
  }, []);

  const onSceneEdgeDelete = useCallback((edges: Edge[]) => {
    // Handle scene edge deletion
    console.log("Scene edge delete requested:", edges);
    // TODO: Implement scene edge deletion logic
  }, []);

  const onSceneNodeDelete = useCallback((nodes: Node[]) => {
    // Handle scene node deletion
    console.log("Scene node delete requested:", nodes);
    // TODO: Implement scene node deletion logic
  }, []);

  const onMomentConnect = useCallback((params: Connection) => {
    // Basic connection validation and creation for moments
    console.log("Moment connection requested:", params);
    // TODO: Implement moment connection logic
  }, []);

  const onMomentEdgeDelete = useCallback((edges: Edge[]) => {
    // Handle moment edge deletion
    console.log("Moment edge delete requested:", edges);
    // TODO: Implement moment edge deletion logic
  }, []);

  const onMomentNodeDelete = useCallback((nodes: Node[]) => {
    // Handle moment node deletion
    console.log("Moment node delete requested:", nodes);
    // TODO: Implement moment node deletion logic
  }, []);

  return {
    // Data
    story,
    moments,
    transitions,
    branchTransitions,
    scenes,
    beats,
    beatsheets,
    branches,
    characters,

    // Scene level
    sceneNodes,
    sceneEdges,
    onSceneNodesChange,
    onSceneEdgesChange,
    onSceneConnect,
    onScenesDrop,
    onScenesLayout,
    onSceneEdgeDelete,
    onSceneNodeDelete,
    updateSceneNode,
    updateScene,

    // Moment level
    momentNodes,
    momentEdges,
    selectedMomentNode,
    setSelectedMomentNode,
    onMomentNodesChange,
    onMomentEdgesChange,
    onMomentConnect,
    onMomentsDrop,
    onMomentEdgeDelete,
    onMomentNodeDelete,
    updateMomentNode,

    // Shared state
    isLoading,
    fetchingErrorMessage,
    refreshData,
  };
};

import React, { useEffect, useMemo } from "react";
import { LoadingSpinner } from "../../LoadingSpinner.tsx";
import DynamicForm from "../../DynamicForm.tsx";
import { ArrowPathIcon } from "@heroicons/react/16/solid";
import { Tables } from "@/types/database.ts";
import { SpokableButton } from "../../SpokableButton.tsx";
import { MomentLearningInput } from "@/types/moment_learning_prompts_generated_types.ts";
import { LEARNING } from "@/components/admin/storyGraph/common/types.ts";
import { ConceptItem, SyllabusOutput } from "@/types/learning_concept_prompts_generated_types.ts";
import { TeachingStyle } from "@/constants/constant.ts";
import { MomentBaseOutput } from "@/types/moment_base_prompts_generated_types.ts";

export interface GenerateMomentProps {
  story: Tables<"blueprint_stories"> | null;
  status: string;
  generatedMoment: MomentBaseOutput | null;
  setGeneratedMoment: React.Dispatch<React.SetStateAction<MomentBaseOutput | null>>;
  generateMoment: (payload: any) => void;
  beatsheets: Tables<"blueprint_beatsheets">[];
  characters: Tables<"blueprint_characters">[];
  sceneBranch: Tables<"blueprint_branches"> | null | undefined;
  previousScenes: Tables<"blueprint_scenes">[] | null;
  previousMomentsInScene: Tables<"blueprint_moments">[] | null;
  scene: Tables<"blueprint_scenes"> | null | undefined;
  syllabus: SyllabusOutput | null;
  selectedSyllabusConcept: ConceptItem | null;
  selectedTeachingStyle: TeachingStyle | null;
}

const GenerateLearningMoment: React.FC<GenerateMomentProps> = ({
  story,
  status,
  generatedMoment,
  setGeneratedMoment,
  generateMoment,
  beatsheets,
  characters,
  sceneBranch,
  previousScenes,
  previousMomentsInScene,
  scene,
  syllabus,
  selectedSyllabusConcept,
  selectedTeachingStyle,
}) => {
  const moment = useMemo(() => generatedMoment, [generatedMoment]);

  useEffect(() => {
    if (generatedMoment === null && status === "" && scene) {
      const params: MomentLearningInput = {
        one_pager: JSON.stringify(sceneBranch?.one_pager),
        character_list: JSON.stringify(characters),
        audience: story?.audience || "",
        previous_scenes: JSON.stringify(previousScenes),
        previous_beatsheets: JSON.stringify(beatsheets),
        previous_scene_moments: JSON.stringify(previousMomentsInScene),
        moment_type: LEARNING,
        generated_moment_scene: JSON.stringify(scene),
        complete_syllabus: JSON.stringify(syllabus),
        learning_teaching_style: selectedTeachingStyle || "",
        learning_sub_concept_to_teach: JSON.stringify(selectedSyllabusConcept),
        seed: "",
      };
      generateMoment(params);
    }
  }, [generatedMoment, characters, scene]);

  const handleRegenerate = () => {
    setGeneratedMoment(null);
  };

  return (
    <div className="w-full max-w-4xl mx-auto p-6 max-h-[70vh] flex flex-col">
      <div className="flex items-center justify-between mb-6 shrink-0">
        <h3 className="text-2xl font-bold">
          Moment
          <SpokableButton
            color="light"
            isIconButton={true}
            className="ml-2"
            onClick={handleRegenerate}
          >
            <ArrowPathIcon />
          </SpokableButton>
          <span className="text-sm ml-2">{status}</span>
        </h3>
      </div>

      {/* Scrollable content area */}
      <div className="flex-1 overflow-y-auto">
        <div className="space-y-6">
          {status !== "" && <LoadingSpinner />}
          {moment && (
            <div className="bg-white rounded-lg">
              <DynamicForm
                data={moment}
                setData={setGeneratedMoment}
                filterIds={true}
                filterDates={true}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default GenerateLearningMoment;

import { useEffect, useRef, useState } from "react";
import { useRecoilState } from "recoil";
import { GameEngineMessageType } from "../constants/GameEngineMessageType.ts";
import { audioDataQueueState } from "../states/audioDataState.ts";
import useMomentManager from "../hooks/useMomentManager.ts";
import { errorAtom } from "../states/storyState.tsx";
import { DTOGameAudioOutput, DTOGameOutput } from "@/types/fastApiGameEngineTypes.ts";

export const useWebSocketMessageHandler = () => {
  const [audioQueue, setAudioQueue] = useRecoilState(audioDataQueueState);
  const [, setErrorMessage] = useRecoilState(errorAtom);
  const audioQueueRef = useRef(audioQueue);

  const [latestGameOutput, setLatestGameOutput] = useState<DTOGameOutput | null>(null);
  useMomentManager({ gameOutput: latestGameOutput });

  useEffect(() => {
    audioQueueRef.current = audioQueue;
  }, [audioQueue]);

  const handleMessage = async (messageType: string, messageDataBuffer: Uint8Array) => {
    const textData = new TextDecoder().decode(messageDataBuffer);

    switch (messageType) {
      case GameEngineMessageType.AUDIO_ANSWER:
        handleAudioAnswer(messageDataBuffer);
        break;

      case GameEngineMessageType.GAME_OUTPUT:
        // eslint-disable-next-line no-case-declarations
        const gameOutput = JSON.parse(textData) as DTOGameOutput;
        setLatestGameOutput(gameOutput);
        break;

      case GameEngineMessageType.ERROR_MESSAGE:
        setErrorMessage(textData);
        break;

      default:
        console.error("Unknown message type:", messageType);
    }
  };

  const handleAudioAnswer = (messageDataBuffer: Uint8Array) => {
    const nullByteIndex = messageDataBuffer.indexOf(0);
    const jsonData = new TextDecoder().decode(messageDataBuffer.slice(0, nullByteIndex));
    const audioData = messageDataBuffer.slice(nullByteIndex + 1);

    const gameAudioOutput: DTOGameAudioOutput = {
      player_moment_id: JSON.parse(jsonData).player_moment_id,
      audio_data: new Uint8Array(audioData),
    };

    setAudioQueue((currentQueue) => [...currentQueue, gameAudioOutput]);
    console.log("AUDIO_ANSWER message received");
  };

  return handleMessage;
};

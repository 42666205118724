import { useEffect } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  analyserDialogueNodeState,
  audioContextState,
  audioDataQueueState,
  isPlayingAudioState,
  playedMomentsState,
} from "../states/audioDataState";

const useDialogueManager = () => {
  const [audioQueue, setAudioQueue] = useRecoilState(audioDataQueueState);
  const [isPlayingAudio, setIsPlayingAudio] = useRecoilState(isPlayingAudioState);
  const [, setPlayedMoments] = useRecoilState(playedMomentsState);
  const audioContext = useRecoilValue(audioContextState);
  const [analyserNode, setAnalyserNode] = useRecoilState(analyserDialogueNodeState);

  useEffect(() => {
    if (!audioContext) return;

    if (!analyserNode) {
      const newAnalyserNode = audioContext.createAnalyser();
      newAnalyserNode.fftSize = 2048;
      setAnalyserNode(newAnalyserNode);
    }
  }, [audioContext, analyserNode, setAnalyserNode]);

  useEffect(() => {
    if (!audioContext || audioQueue.length === 0 || isPlayingAudio || !analyserNode) return;

    setIsPlayingAudio(true);
    const nextAudioData = audioQueue[0];
    const blob = new Blob([nextAudioData.audio_data as Uint8Array], { type: "audio/mp3" });
    const reader = new FileReader();

    reader.onload = (event: ProgressEvent<FileReader>) => {
      const arrayBuffer = event.target?.result as ArrayBuffer | null;
      if (arrayBuffer) {
        audioContext.decodeAudioData(arrayBuffer).then((decodedData) => {
          const source = audioContext.createBufferSource();
          source.buffer = decodedData;
          source.connect(analyserNode).connect(audioContext.destination);
          source.start();
          source.onended = () => {
            setAudioQueue((queue) => queue.slice(1));
            setIsPlayingAudio(false);
            setPlayedMoments((moments) => [...moments, nextAudioData.player_moment_id]);
          };
        });
      }
    };

    reader.readAsArrayBuffer(blob);
  }, [
    audioQueue,
    isPlayingAudio,
    audioContext,
    setAudioQueue,
    setIsPlayingAudio,
    setPlayedMoments,
    analyserNode,
  ]);

  const getFrequencyData = () => {
    if (!analyserNode) return null;
    const dataArray = new Uint8Array(analyserNode.frequencyBinCount);
    analyserNode.getByteFrequencyData(dataArray);
    return dataArray;
  };

  return {
    isPlayingAudio,
    getFrequencyData,
  };
};

export default useDialogueManager;

import { atom } from "recoil";

export const modalStoryGenerateState = atom<boolean>({
  key: "modalGenerateState",
  default: false,
});

export const modalBeatGenerateState = atom<boolean>({
  key: "modalBeatGenerateState",
  default: false,
});

export const modalScenesGenerateState = atom<boolean>({
  key: "modalSceneGenerateState",
  default: false,
});

export const modalMomentsGenerateState = atom<boolean>({
  key: "modalMomentGenerateState",
  default: false,
});

export const modalSoundGenerateState = atom<boolean>({
  key: "modalSoundGenerateState",
  default: false,
});

export const modalAutoCompleteState = atom<boolean>({
  key: "modalAutoCompleteState",
  default: false,
});
export const modalVoicePreviewState = atom<boolean>({
  key: "modalVoicePreviewState",
  default: false,
});

export const modalUpscaleImageState = atom<boolean>({
  key: "modalUpscaleImageState",
  default: false,
});
export const modalImageGenerateState = atom<boolean>({
  key: "modalImageGenerateState",
  default: false,
});

export const popupOpenState = atom({
  key: "popupOpenState",
  default: false,
});

interface GameEngineModalState {
  isOpen: boolean;
  storyId: string | null;
}

export const gameEngineModalState = atom<GameEngineModalState>({
  key: "gameEngineModalState",
  default: {
    isOpen: false,
    storyId: null,
  },
});

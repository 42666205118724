import { GameEngineMessageType } from "../constants/GameEngineMessageType.ts";
import { Tables } from "../types/database.ts";
import { DTOGameInput } from "@/types/fastApiGameEngineTypes.ts";

export class WebSocketWrapper {
  public ws: WebSocket;

  constructor(ws: WebSocket) {
    this.ws = ws;
  }

  public sendMessage = (
    type: GameEngineMessageType,
    momentPromptElements: Tables<"game_engine_view">,
    audioData?: Blob,
  ) => {
    const gameInput: DTOGameInput = { ...momentPromptElements };

    if (this.ws.readyState === WebSocket.OPEN) {
      const typeBuffer = new TextEncoder().encode(type + "\0");
      const promptBuffer = new TextEncoder().encode(JSON.stringify(gameInput) + "\0"); // Add null byte here

      if (audioData) {
        const reader = new FileReader();
        reader.onload = () => {
          const audioDataBuffer = reader.result || new ArrayBuffer(0);
          console.log(`Audio data size (bytes): ${(audioDataBuffer as ArrayBuffer).byteLength}`);
          this.combineAndSend(typeBuffer, promptBuffer, audioDataBuffer as ArrayBuffer); // Order changed to type, prompt, audio
        };
        reader.readAsArrayBuffer(audioData);
      } else {
        this.combineAndSend(typeBuffer, promptBuffer, new ArrayBuffer(0)); // No audio data case
      }
    } else {
      console.error("WebSocket is not connected.");
    }
  };

  private combineAndSend = (
    typeBuffer: ArrayBuffer,
    promptBuffer: ArrayBuffer,
    dataBuffer: ArrayBuffer,
  ) => {
    const combinedBuffer = new ArrayBuffer(
      typeBuffer.byteLength + promptBuffer.byteLength + dataBuffer.byteLength,
    );
    const combinedView = new Uint8Array(combinedBuffer);

    // First, set the typeBuffer
    combinedView.set(new Uint8Array(typeBuffer), 0);

    // Next, set the promptBuffer right after the typeBuffer
    combinedView.set(new Uint8Array(promptBuffer), typeBuffer.byteLength);

    // Finally, set the dataBuffer (audioData) after the promptBuffer
    combinedView.set(new Uint8Array(dataBuffer), typeBuffer.byteLength + promptBuffer.byteLength);

    this.ws.send(combinedBuffer);
  };
}

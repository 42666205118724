import React, { useState } from "react";
import { supabase } from "@/vendor/supabaseClient.ts";
import { Tables, TablesInsert } from "@/types/database.ts";
import { MomentBaseOutput } from "@/types/moment_base_prompts_generated_types.ts";
import { MomentType } from "@/components/admin/storyGraph/common/types.ts";
import { TeachingStyle } from "@/constants/constant.ts";
import { ConceptItem } from "@/types/learning_concept_prompts_generated_types.ts";
import { findPreviousMoment } from "@/components/admin/storyGraph/util/reactFlowUtil.ts";
import SaveAllStepUI from "../SaveAllStepUI.tsx";

export interface SaveAllProps {
  story: Tables<"blueprint_stories"> | null;
  moment: MomentBaseOutput | null;
  scene: Tables<"blueprint_scenes"> | null | undefined;
  momentType: MomentType;
  teachingStyle: TeachingStyle;
  handleClose: () => void;
  selectedSyllabusConcept: ConceptItem | null;
  previousScenes: Tables<"blueprint_scenes">[] | null;
  allMoments: Tables<"blueprint_moments">[] | null;
  allMomentTransitions: Tables<"blueprint_moment_transitions">[];
}

const SaveAll: React.FC<SaveAllProps> = ({
  story,
  moment,
  scene,
  momentType,
  teachingStyle,
  handleClose,
  selectedSyllabusConcept,
  allMoments,
  previousScenes,
  allMomentTransitions,
}) => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const saveAll = async () => {
    if (!moment || !story) {
      setErrorMessage("Missing moments or story data");
      return;
    }

    setErrorMessage(null);

    try {
      const savedMoment = await createMoment(moment);

      if (!savedMoment) return;
      const previousMoment = findPreviousMoment(
        savedMoment,
        allMoments,
        previousScenes,
        allMomentTransitions,
      );
      if (!previousMoment) return;
      await createTransition(previousMoment, savedMoment);

      handleClose();
    } catch (error) {
      setErrorMessage(
        `Error saving moments: ${error instanceof Error ? error.message : String(error)}`,
      );
    }
  };

  async function createMoment(
    moment: MomentBaseOutput,
  ): Promise<Tables<"blueprint_moments"> | null> {
    if (!story) return null;
    if (!scene) return null;

    const momentsData: TablesInsert<"blueprint_moments"> = {
      blueprint_character_id: moment.blueprint_character_id,
      blueprint_learning_sub_concept_id: selectedSyllabusConcept?.sub_concept_id,
      blueprint_story_id: story.id,
      character_development_contribution: moment.character_development_contribution,
      conditions_failure: moment.conditions_failure,
      conditions_success: moment.conditions_success,
      conflict_resolution: moment.conflict_resolution,
      gate_puzzle_type: null,
      is_starting_moment: false,
      learning_teaching_style: teachingStyle,
      main_conflict: moment.main_conflict,
      moment_name: moment.moment_name,
      moment_rules: moment.moment_rules,
      moment_setting: moment.moment_setting,
      moment_type: momentType,
      plot_contribution: moment.plot_contribution,
      prompt_background_sound: null,
      prompt_background_video_generation: null,
      prompt_event_video_generation: null,
      prompt_image_generation: null,
      prompt_sound_intro_generation: null,
      prompt_sound_outro_generation: null,
      scene_id: scene.id,
      sound_background: null,
      sound_background_is_looping: false,
      sound_intro: null,
      sound_intro_is_looping: false,
      sound_intro_volume: 1,
      sound_outro: null,
      sound_outro_volume: 1,
      starting_dialogue: moment.starting_dialogue,
      testing_max_mistakes: null,
      testing_style: null,
    };

    const { data, error } = await supabase
      .from("blueprint_moments")
      .insert(momentsData)
      .select()
      .single();

    if (error) {
      throw new Error(`Error inserting moment: ${error.message}`);
    } else {
      setErrorMessage((prev) => `${prev ? prev + "\n" : ""}Saved moment: ${data.moment_name}`);
      return data;
    }
  }

  async function createTransition(
    currentMoment: Tables<"blueprint_moments">,
    nextMoment: Tables<"blueprint_moments">,
  ): Promise<void> {
    if (!story) return;

    const transitionData: TablesInsert<"blueprint_moment_transitions"> = {
      blueprint_story_id: story.id,
      condition: "",
      current_moment_id: currentMoment.id,
      next_moment_id: nextMoment.id,
    };

    const { error } = await supabase.from("blueprint_moment_transitions").insert(transitionData);

    if (error) {
      throw new Error(`Error creating transition: ${error.message}`);
    } else {
      setErrorMessage(
        (prev) =>
          `${prev ? prev + "\n" : ""}Created transition from ${currentMoment.moment_name} to ${nextMoment.moment_name}`,
      );
    }
  }

  return <SaveAllStepUI onSave={saveAll} errorMessage={errorMessage} />;
};

export default SaveAll;

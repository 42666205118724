import React, { useState } from "react";
import { OnePager } from "@/types/fastApiTypes.ts";
import { supabase } from "@/vendor/supabaseClient.ts";
import { Tables, TablesInsert } from "@/types/database.ts";
import { BRANCH_NAME_MAIN } from "@/constants/constant.ts";
import { LogLine } from "@/types/logline_prompts_generated_types.ts";
import { CharacterSheet, CharactersSummary } from "@/types/character_prompts_generated_types.ts";
import { SyllabusOutput } from "@/types/learning_concept_prompts_generated_types.ts";
import { useSyllabusDatabase } from "@/components/admin/syllabusManagement/useSyllabusDatabase.ts";
import SaveAllStepUI from "@/components/admin/SaveAllStepUI.tsx";

export interface SaveAllProps {
  onePager: OnePager | null;
  charactersSummary: CharactersSummary | null;
  logLine: LogLine | null;
  story: Tables<"blueprint_stories">;
  handleClose: () => void;
  audience: string;
  syllabus: SyllabusOutput | null;
}

const SaveAll: React.FC<SaveAllProps> = ({
  onePager,
  charactersSummary,
  logLine,
  story,
  handleClose,
  audience,
  syllabus,
}) => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const { upsertSyllabus } = useSyllabusDatabase();

  const saveAll = async () => {
    if (!syllabus) return;
    updateStory();
    createCharacters();
    createBranch();
    upsertSyllabus(syllabus, story.id);
    handleClose();
  };

  async function updateStory() {
    if (!story) return;

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { id, ...storyWithoutId } = story;
    storyWithoutId.name = onePager?.title || "";
    storyWithoutId.catalogue_description = logLine?.log_line || "";
    storyWithoutId.image_prompt = onePager?.image_generation_prompt || "";
    storyWithoutId.image_prompt_style = onePager?.aesthetic_style || "";
    storyWithoutId.audience = audience;
    storyWithoutId.is_onboarding_done = true;

    const { error } = await supabase
      .from("blueprint_stories")
      .update(storyWithoutId)
      .eq("id", story.id);

    if (error) {
      setErrorMessage("Error updating story: " + error.message);
    } else {
      setErrorMessage("Saved! ");
    }
  }

  async function createBranch() {
    if (!story) return;

    // Check if the main branch already exists for this story
    const { data: existingBranch } = await supabase
      .from("blueprint_branches")
      .select()
      .eq("story_id", story.id)
      .eq("name", BRANCH_NAME_MAIN)
      .limit(1)
      .single();

    if (existingBranch) {
      return existingBranch;
    }

    const branchData: TablesInsert<"blueprint_branches"> = {
      name: BRANCH_NAME_MAIN,
      one_pager: JSON.stringify(onePager),
      story_id: story.id,
    };
    const { data, error } = await supabase
      .from("blueprint_branches")
      .insert(branchData)
      .select()
      .single();

    if (error) {
      setErrorMessage("Error updating story: " + error.message);
    } else {
      setErrorMessage("Saved!");
      return data;
    }
  }

  async function createCharacters() {
    if (!story) return;

    charactersSummary?.character_sheets.map((characterSheet) => createCharacter(characterSheet));
  }

  async function createCharacter(characterSheet: CharacterSheet) {
    const characterData: TablesInsert<"blueprint_characters"> = {
      ...characterSheet,
      blueprint_story_id: story.id,
    };

    const { error } = await supabase
      .from("blueprint_characters")
      .insert(characterData)
      .select()
      .single();

    if (error) {
      setErrorMessage("Error updating character: " + error.message);
    } else {
      setErrorMessage("Saved!");
    }
  }

  return <SaveAllStepUI onSave={saveAll} errorMessage={errorMessage} />;
};

export default SaveAll;

import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { ArrowDown, ArrowUp, Check, Pencil, Plus, X } from "lucide-react";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";
import { DndContext } from "@dnd-kit/core";
import { SortableContext, verticalListSortingStrategy } from "@dnd-kit/sortable";
import { SyllabusOutput } from "@/types/learning_concept_prompts_generated_types";
import { SortableItem } from "./SortableItem";
import {
  useEditState,
  useGroupedItems,
  useSortableItems,
  useSyllabusSubject,
  useSyllabusUpdates,
} from "@/components/admin/syllabusManagement/useSyllabusEditor.ts";
import React from "react";

interface SyllabusEditorProps {
  syllabus: SyllabusOutput;
  setSyllabus: React.Dispatch<React.SetStateAction<SyllabusOutput | null>>;
}

const SyllabusEditor = ({ syllabus, setSyllabus }: SyllabusEditorProps) => {
  const {
    isEditing: editingSubject,
    tempSubject,
    setTempSubject,
    handleStartEdit: handleStartEditSubject,
    handleCancel: handleCancelEditSubject,
    handleSave: handleSaveSubject,
  } = useSyllabusSubject(syllabus.subject, (newSubject) => {
    setSyllabus({ ...syllabus, subject: newSubject });
  });

  const { sensors, collisionDetection } = useSortableItems();
  const itemsByConceptId = useGroupedItems(syllabus.items);
  const { editingConceptId, setEditingConceptId, editingItemId, setEditingItemId } = useEditState();

  const {
    handleUpdateConcept,
    handleDeleteConcept,
    handleAddItem,
    handleUpdateItem,
    handleDeleteItem,
    handleConceptDragEnd,
    handleItemDragEnd,
    handleAddConcept,
    handleReorderItem,
  } = useSyllabusUpdates(syllabus, setSyllabus);

  return (
    <div className="max-w-4xl mx-auto p-4 space-y-4">
      <Card>
        <CardHeader className="flex flex-row items-center justify-between space-y-0 ">
          {editingSubject ? (
            <div className="flex gap-2 items-center flex-1">
              <Input
                value={tempSubject}
                onChange={(e) => setTempSubject(e.target.value)}
                className="max-w-sm"
              />
              <Button size="icon" variant="ghost" onClick={handleSaveSubject}>
                <Check className="h-4 w-4" />
              </Button>
              <Button size="icon" variant="ghost" onClick={handleCancelEditSubject}>
                <X className="h-4 w-4" />
              </Button>
            </div>
          ) : (
            <CardTitle className="flex items-center gap-2">
              {syllabus.subject}
              <Button size="icon" variant="ghost" onClick={handleStartEditSubject}>
                <Pencil className="h-4 w-4" />
              </Button>
            </CardTitle>
          )}
          <Button onClick={handleAddConcept} size="sm">
            <Plus className="h-4 w-4 mr-2" /> Add Concept
          </Button>
        </CardHeader>
      </Card>

      <DndContext
        sensors={sensors}
        collisionDetection={collisionDetection}
        onDragEnd={handleConceptDragEnd}
      >
        <SortableContext
          items={syllabus.learning_path.map((c) => c.concept_id)}
          strategy={verticalListSortingStrategy}
        >
          <Accordion type="single" collapsible className="space-y-2">
            {syllabus.learning_path.map((concept) => (
              <SortableItem key={concept.concept_id} id={concept.concept_id}>
                <AccordionItem value={concept.concept_id}>
                  <AccordionTrigger className="hover:bg-gray-100 rounded-lg px-4">
                    <div className="flex items-center gap-2">
                      <span>{concept.concept_name}</span>
                      {concept.prerequisites && concept.prerequisites?.length > 0 && (
                        <Badge variant="secondary" className="ml-2">
                          {concept.prerequisites.length} prerequisites
                        </Badge>
                      )}
                    </div>
                  </AccordionTrigger>
                  <AccordionContent className="px-4">
                    <div className="space-y-4">
                      {editingConceptId === concept.concept_id ? (
                        <div className="space-y-4">
                          <Input
                            value={concept.concept_name}
                            onChange={(e) =>
                              handleUpdateConcept(concept.concept_id, {
                                concept_name: e.target.value,
                              })
                            }
                            placeholder="Concept Name"
                          />
                          <Textarea
                            value={concept.learning_summary}
                            onChange={(e) =>
                              handleUpdateConcept(concept.concept_id, {
                                learning_summary: e.target.value,
                              })
                            }
                            placeholder="Learning Summary"
                          />
                          <div className="flex gap-2">
                            <Button
                              size="sm"
                              variant="outline"
                              onClick={() => setEditingConceptId(null)}
                            >
                              Save
                            </Button>
                            <Button
                              size="sm"
                              variant="destructive"
                              onClick={() => handleDeleteConcept(concept.concept_id)}
                            >
                              Delete Concept
                            </Button>
                          </div>
                        </div>
                      ) : (
                        <div className="flex justify-between items-start">
                          <div className="text-sm text-gray-600">{concept.learning_summary}</div>
                          <Button
                            size="sm"
                            variant="ghost"
                            onClick={() => setEditingConceptId(concept.concept_id)}
                          >
                            <Pencil className="h-4 w-4" />
                          </Button>
                        </div>
                      )}

                      <div className="space-y-2">
                        <div className="flex justify-between items-center">
                          <h4 className="font-medium">Concept Items</h4>
                          <Button
                            size="sm"
                            variant="outline"
                            onClick={() => handleAddItem(concept.concept_id)}
                          >
                            <Plus className="h-4 w-4 mr-2" /> Add Item
                          </Button>
                        </div>

                        <DndContext
                          sensors={sensors}
                          collisionDetection={collisionDetection}
                          onDragEnd={(event) => handleItemDragEnd(concept.concept_id, event)}
                        >
                          <SortableContext
                            items={
                              itemsByConceptId[concept.concept_id]?.map(
                                (item) => `${item.concept_id}-${item.sub_concept_id}`,
                              ) || []
                            }
                            strategy={verticalListSortingStrategy}
                          >
                            <div className="grid gap-2">
                              {itemsByConceptId[concept.concept_id]?.map((item) => {
                                const itemId = `${item.concept_id}-${item.sub_concept_id}`;
                                const isEditing = editingItemId === itemId;

                                return (
                                  <SortableItem key={itemId} id={itemId}>
                                    <Card>
                                      <CardContent className="p-4">
                                        {isEditing ? (
                                          <div className="space-y-4">
                                            <Input
                                              value={item.name}
                                              onChange={(e) =>
                                                handleUpdateItem(itemId, { name: e.target.value })
                                              }
                                              placeholder="Item Name"
                                            />
                                            <div className="grid grid-cols-3 gap-2">
                                              <Input
                                                value={item.noun}
                                                onChange={(e) =>
                                                  handleUpdateItem(itemId, { noun: e.target.value })
                                                }
                                                placeholder="Noun"
                                              />
                                              <Input
                                                value={item.verb}
                                                onChange={(e) =>
                                                  handleUpdateItem(itemId, { verb: e.target.value })
                                                }
                                                placeholder="Verb"
                                              />
                                              <Input
                                                value={item.adjective}
                                                onChange={(e) =>
                                                  handleUpdateItem(itemId, {
                                                    adjective: e.target.value,
                                                  })
                                                }
                                                placeholder="Adjective"
                                              />
                                            </div>
                                            <div className="flex gap-2">
                                              <Button
                                                size="sm"
                                                variant="outline"
                                                onClick={() => setEditingItemId(null)}
                                              >
                                                Done
                                              </Button>
                                              <Button
                                                size="sm"
                                                variant="destructive"
                                                onClick={() => handleDeleteItem(itemId)}
                                              >
                                                Delete Item
                                              </Button>
                                            </div>
                                          </div>
                                        ) : (
                                          <div className="flex justify-between items-start">
                                            <div>
                                              <h5 className="font-medium">{item.name}</h5>
                                              <div className="text-sm text-gray-600 mt-1">
                                                Order: {item.order}
                                              </div>
                                            </div>
                                            <div className="flex items-center gap-2">
                                              <div className="flex gap-2">
                                                <Badge>{item.noun}</Badge>
                                                <Badge variant="outline">{item.verb}</Badge>
                                                <Badge variant="secondary">{item.adjective}</Badge>
                                              </div>
                                              <div className="flex flex-col gap-1">
                                                <Button
                                                  size="icon"
                                                  variant="ghost"
                                                  onClick={() =>
                                                    handleReorderItem(
                                                      concept.concept_id,
                                                      item.order,
                                                      "up",
                                                    )
                                                  }
                                                  disabled={item.order === 1}
                                                >
                                                  <ArrowUp className="h-4 w-4" />
                                                </Button>
                                                <Button
                                                  size="icon"
                                                  variant="ghost"
                                                  onClick={() =>
                                                    handleReorderItem(
                                                      concept.concept_id,
                                                      item.order,
                                                      "down",
                                                    )
                                                  }
                                                  disabled={
                                                    item.order ===
                                                    itemsByConceptId[concept.concept_id]?.length
                                                  }
                                                >
                                                  <ArrowDown className="h-4 w-4" />
                                                </Button>
                                              </div>
                                              <Button
                                                size="icon"
                                                variant="ghost"
                                                onClick={() => setEditingItemId(itemId)}
                                              >
                                                <Pencil className="h-4 w-4" />
                                              </Button>
                                            </div>
                                          </div>
                                        )}
                                      </CardContent>
                                    </Card>
                                  </SortableItem>
                                );
                              })}
                            </div>
                          </SortableContext>
                        </DndContext>
                      </div>

                      <div className="space-y-2">
                        <h4 className="font-medium">Learning Outcomes</h4>
                        <ul className="list-disc pl-5 space-y-1">
                          {concept.learning_outcomes.map((outcome, idx) => (
                            <li key={idx} className="text-sm">
                              {outcome}
                            </li>
                          ))}
                        </ul>
                      </div>

                      <div className="space-y-2">
                        <h4 className="font-medium">Practical Exercises</h4>
                        <ul className="list-disc pl-5 space-y-1">
                          {concept.practical_exercises.map((exercise, idx) => (
                            <li key={idx} className="text-sm">
                              {exercise}
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </AccordionContent>
                </AccordionItem>
              </SortableItem>
            ))}
          </Accordion>
        </SortableContext>
      </DndContext>
    </div>
  );
};

export default SyllabusEditor;

import React from "react";
import { useRecoilState } from "recoil";
import { Dialog, DialogContent } from "@/components/ui/dialog";
import { gameEngineModalState } from "@/states/ModalState.ts";
import GameScreen from "@/pages/GameScreen.tsx";

export const GameEngineModal: React.FC = () => {
  const [modalState, setModalState] = useRecoilState(gameEngineModalState);

  const handleClose = () => {
    setModalState({ isOpen: false, storyId: null });
  };

  if (!modalState.storyId) return null;

  return (
    <Dialog open={modalState.isOpen} onOpenChange={handleClose}>
      <DialogContent className="max-w-[90vw] max-h-[90vh] h-full w-full">
        <div className="overflow-auto h-full">
          <GameScreen />
        </div>
      </DialogContent>
    </Dialog>
  );
};

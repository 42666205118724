import { Tables } from "@/types/database.ts";
import { SceneNode } from "@/components/admin/storyGraph/common/types.ts";
import { useSceneFlow } from "@/components/admin/storyGraph/sceneLevel/useSceneFlow.ts";
import { useMomentFlow } from "@/components/admin/storyGraph/momentLevel/useMomentFlow.ts";

interface StoryFlowData {
  moments: Tables<"blueprint_moments">[];
  transitions: Tables<"blueprint_moment_transitions">[];
  scenes: Tables<"blueprint_scenes">[];
  beats: Tables<"blueprint_beats">[];
  beatsheets: Tables<"blueprint_beatsheets">[];
  branches: Tables<"blueprint_branches">[];
  characters: Tables<"blueprint_characters">[];
  selectedSceneNode: SceneNode | null;
}

export function useStoryFlow(allStoryData: StoryFlowData) {
  const {
    sceneNodes,
    sceneEdges,
    onSceneNodesChange,
    onSceneEdgesChange,
    onSceneConnect,
    onScenesDrop,
    onScenesLayout,
    updateSceneNode,
  } = useSceneFlow({
    scenes: allStoryData.scenes,
    beatsheets: allStoryData.beatsheets,
    beats: allStoryData.beats,
    branches: allStoryData.branches,
  });
  const {
    momentNodes,
    momentEdges,
    selectedMomentNode,
    setSelectedMomentNode,
    onMomentNodesChange,
    onMomentEdgesChange,
    onMomentConnect,
    onMomentsDrop,
    onMomentsLayout,
    updateMomentNode,
  } = useMomentFlow({
    moments: allStoryData.moments,
    transitions: allStoryData.transitions,
    selectedSceneNode: allStoryData.selectedSceneNode,
    scenes: allStoryData.scenes,
    beatsheets: allStoryData.beatsheets,
    beats: allStoryData.beats,
    branches: allStoryData.branches,
  });
  // Return the same interface:
  return {
    // Scene level
    sceneNodes,
    sceneEdges,
    onSceneNodesChange,
    onSceneEdgesChange,
    onSceneConnect,
    onScenesDrop,
    onScenesLayout,
    updateSceneNode,
    // Moment level
    momentNodes,
    momentEdges,
    selectedMomentNode,
    setSelectedMomentNode,
    onMomentNodesChange,
    onMomentEdgesChange,
    onMomentConnect,
    onMomentsDrop,
    onMomentsLayout,
    updateMomentNode,
  };
}

import { Tables } from "@/types/database";
import { SCENE, SceneEdge, SceneNode } from "@/components/admin/storyGraph/common/types";
import {
  findById,
  sortBeatsheetsByBeatOrder,
  sortScenesByOrder,
} from "@/components/admin/storyGraph/common/util";
import { groupBy } from "lodash";

interface InitialSceneNodeProps {
  id: string;
  position: { x: number; y: number };
  sceneOrder: number;
  label?: string;
  beatsheet: Tables<"blueprint_beatsheets"> | null;
  beat: Tables<"blueprint_beats"> | null;
  branch: Tables<"blueprint_branches"> | null;
  scene: Tables<"blueprint_scenes"> | null;
}

// Create an empty scene node
export function createEmptySceneNode({
  id,
  position,
  sceneOrder,
  label = "New Scene",
  beatsheet,
  beat,
  branch,
  scene,
}: InitialSceneNodeProps): SceneNode {
  return {
    id,
    type: SCENE,
    position,
    data: {
      label,
      type: SCENE,
      isInitialized: false,
      sceneOrder,
      beatsheet,
      beat,
      branch,
      scene,
    },
    style: { border: "none" },
  };
}

interface InitialSceneEdgeProps {
  id: string;
  source: string;
  target: string;
  fromBeatsheet: Tables<"blueprint_beatsheets"> | null;
  toBeatsheet: Tables<"blueprint_beatsheets"> | null;
  fromBeat: Tables<"blueprint_beats"> | null;
  toBeat: Tables<"blueprint_beats"> | null;
  branch: Tables<"blueprint_branches"> | null;
  fromScene: Tables<"blueprint_scenes"> | null;
  toScene: Tables<"blueprint_scenes"> | null;
  isIntraBeatsheet: boolean;
}

// Create an empty scene edge
export function createEmptySceneEdge({
  id,
  source,
  target,
  fromBeatsheet,
  toBeatsheet,
  fromBeat,
  toBeat,
  branch,
  fromScene,
  toScene,
  isIntraBeatsheet,
}: InitialSceneEdgeProps): SceneEdge {
  return {
    id,
    source,
    target,
    type: "smoothstep",
    data: {
      fromBeatsheet,
      toBeatsheet,
      fromBeat,
      toBeat,
      branch,
      fromScene,
      toScene,
      isIntraBeatsheet,
    },
  };
}

// Create scene nodes from existing data
export function createSceneNodes(
  scenes: Tables<"blueprint_scenes">[],
  beatsheets: Tables<"blueprint_beatsheets">[],
  beats: Tables<"blueprint_beats">[],
  branches: Tables<"blueprint_branches">[],
): SceneNode[] {
  return scenes.map((scene, index) => {
    const beatsheet = findById(beatsheets, scene.beatsheet_id);
    const beat = findById(beats, beatsheet?.beat_id);
    const branch = findById(branches, beatsheet?.branch_id);

    const node = createEmptySceneNode({
      id: `scene-${scene.id}`,
      position: { x: 100, y: index * 100 },
      sceneOrder: scene.scene_order,
      label: scene.name,
      beatsheet,
      beat,
      branch,
      scene,
    });

    // Override with initialized state for existing data
    return {
      ...node,
      data: {
        ...node.data,
        isInitialized: true,
      },
    };
  });
}

// Create scene edges from existing data
export function createSceneEdges(
  scenes: Tables<"blueprint_scenes">[],
  beatsheets: Tables<"blueprint_beatsheets">[],
  beats: Tables<"blueprint_beats">[],
  branches: Tables<"blueprint_branches">[],
): SceneEdge[] {
  const edges: SceneEdge[] = [];

  // 1) Intra-beatsheet edges (within the same beatsheet)
  const scenesByBeatsheet = groupBy(scenes, (s) => s.beatsheet_id);
  Object.entries(scenesByBeatsheet).forEach(([beatsheetId, groupedScenes]) => {
    const sortedScenes = sortScenesByOrder(groupedScenes);
    const beatsheet = findById(beatsheets, beatsheetId);
    const beat = findById(beats, beatsheet?.beat_id);
    const branch = findById(branches, beatsheet?.branch_id);

    for (let i = 0; i < sortedScenes.length - 1; i++) {
      edges.push(
        createEmptySceneEdge({
          id: `scene-${sortedScenes[i].id}-${sortedScenes[i + 1].id}`,
          source: `scene-${sortedScenes[i].id}`,
          target: `scene-${sortedScenes[i + 1].id}`,
          fromBeatsheet: beatsheet,
          toBeatsheet: beatsheet,
          fromBeat: beat,
          toBeat: beat,
          branch,
          fromScene: sortedScenes[i],
          toScene: sortedScenes[i + 1],
          isIntraBeatsheet: true,
        }),
      );
    }
  });

  // 2) Inter-beatsheet edges (connecting beatsheets in the same branch)
  const beatsheetsByBranch = groupBy(beatsheets, (bs) => bs.branch_id);
  Object.entries(beatsheetsByBranch).forEach(([branchId, branchBeatsheets]) => {
    const sortedBeatsheets = sortBeatsheetsByBeatOrder(branchBeatsheets, beats);

    for (let i = 0; i < sortedBeatsheets.length - 1; i++) {
      const fromBs = sortedBeatsheets[i];
      const toBs = sortedBeatsheets[i + 1];
      const fromScenes = sortScenesByOrder(scenesByBeatsheet[fromBs.id] || []);
      const toScenes = sortScenesByOrder(scenesByBeatsheet[toBs.id] || []);
      const lastScene = fromScenes[fromScenes.length - 1];
      const firstScene = toScenes[0];

      if (lastScene && firstScene) {
        const fromBeat = findById(beats, fromBs.beat_id);
        const toBeat = findById(beats, toBs.beat_id);
        const branch = findById(branches, branchId);

        edges.push(
          createEmptySceneEdge({
            id: `beatsheet-${fromBs.id}-${toBs.id}`,
            source: `scene-${lastScene.id}`,
            target: `scene-${firstScene.id}`,
            fromBeatsheet: fromBs,
            toBeatsheet: toBs,
            fromBeat,
            toBeat,
            branch,
            fromScene: lastScene,
            toScene: firstScene,
            isIntraBeatsheet: false,
          }),
        );
      }
    }
  });

  return edges;
}

import React, { useState } from "react";
import { Input } from "@/components/ui/input";
import AutoResizeTextArea from "@/components/admin/AutoResizeTextArea.tsx";

type JsonValue = string | number | boolean | null | JsonValue[] | { [key: string]: JsonValue };

interface JsonEditorProps {
  initialData: { [key: string]: JsonValue };
  onChange?: (data: { [key: string]: JsonValue }) => void;
}

export const DynamicJsonEditor: React.FC<JsonEditorProps> = ({ initialData, onChange }) => {
  const [data, setData] = useState<{ [key: string]: JsonValue }>(initialData);

  const handleChange = (key: string, value: JsonValue) => {
    const newData = {
      ...data,
      [key]: value,
    };
    setData(newData);
    onChange?.(newData);
  };

  const renderField = (key: string, value: JsonValue) => {
    const type = typeof value;
    const fieldId = `field-${key}`;

    if (type === "string") {
      return (
        <div key={key} className="space-y-2 mt-6">
          <label className="capitalize block text-sm text-zinc-500 font-medium mb-2">
            {key.replace(/_/g, " ")}
          </label>
          <AutoResizeTextArea
            value={value as string}
            onChange={(e) => handleChange(key, e)}
            minNumberOfRows={1}
          />
        </div>
      );
    }

    if (type === "number") {
      return (
        <div key={key} className="space-y-2 mt-6">
          <label className="capitalize block text-sm text-zinc-500 font-medium mb-2">
            {key.replace(/_/g, " ")}
          </label>
          <Input
            id={fieldId}
            type="number"
            value={value as number}
            onChange={(e) => handleChange(key, Number(e.target.value))}
          />
        </div>
      );
    }

    if (type === "boolean") {
      return (
        <div key={key} className="space-y-2 mt-6">
          <label className="capitalize block text-sm text-zinc-500 font-medium mb-2">
            {key.replace(/_/g, " ")}
          </label>
          <Input
            id={fieldId}
            type="checkbox"
            checked={value as boolean}
            onChange={(e) => handleChange(key, e.target.checked)}
          />
        </div>
      );
    }

    return null;
  };

  return <div>{Object.entries(data).map(([key, value]) => renderField(key, value))}</div>;
};

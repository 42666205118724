import { Tables } from "@/types/database.ts";

export function findById<T extends { id: string }>(list: T[], id?: string): T | null {
  return id ? list.find((item) => item.id === id) ?? null : null;
}

export function sortScenesByOrder(scenes: Tables<"blueprint_scenes">[]) {
  return [...scenes].sort((a, b) => a.scene_order - b.scene_order);
}

export function sortBeatsheetsByBeatOrder(
  beatsheets: Tables<"blueprint_beatsheets">[],
  beats: Tables<"blueprint_beats">[],
) {
  return [...beatsheets].sort((a, b) => {
    const beatA = findById(beats, a.beat_id);
    const beatB = findById(beats, b.beat_id);
    return (beatA?.beat_order || 0) - (beatB?.beat_order || 0);
  });
}

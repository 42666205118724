import { useEffect, useRef } from "react";
import { useRecoilState } from "recoil";
import {
  currentCharacterEmotionState,
  isGameLostState,
  isMomentCompleteState,
  momentElementsAtom,
  objectiveHasJustBeenCompletedState,
  useCreatePlayerMoments,
  useUpdatePlayerMoment,
} from "../states/momentState.tsx";
import { Tables } from "../types/database.ts";
import {
  audioDataQueueState,
  isThinkingState,
  playedMomentsState,
} from "../states/audioDataState.ts";
import { DTOGameOutput } from "@/types/fastApiGameEngineTypes.ts";

interface MomentManagerHookProps {
  gameOutput: DTOGameOutput | null;
}

const useMomentManager = ({ gameOutput }: MomentManagerHookProps) => {
  const [momentPrompts] = useRecoilState(momentElementsAtom);
  const momentPromptsRef = useRef(momentPrompts);
  const updatePlayerMoment = useUpdatePlayerMoment();
  const createPlayerMoment = useCreatePlayerMoments();
  const [, setIsMomentComplete] = useRecoilState(isMomentCompleteState);
  const [, setIsGameLost] = useRecoilState(isGameLostState);
  const [, setIsThinking] = useRecoilState(isThinkingState);
  const [, setObjectiveHasJustBeCompleted] = useRecoilState(objectiveHasJustBeenCompletedState);
  const [, setCurrentCharacterEmotion] = useRecoilState(currentCharacterEmotionState);
  const [playedMoments] = useRecoilState(playedMomentsState);
  const [audioQueue] = useRecoilState(audioDataQueueState);

  useEffect(() => {
    momentPromptsRef.current = momentPrompts;
  }, [momentPrompts]);

  useEffect(() => {
    if (!gameOutput) return;
    console.log(gameOutput);
    updatePlayerMomentWithGameOutput(gameOutput);
    setIsThinking(false);
  }, [gameOutput]);

  useEffect(() => {
    if (!gameOutput) return;
    manageMomentState(gameOutput);
  }, [audioQueue, playedMoments, gameOutput]);

  const updatePlayerMomentWithGameOutput = async (gameOutput: DTOGameOutput) => {
    const momentPrompt = momentPromptsRef.current[0];
    const updatedDialogueHistory = getUpdatedDialogue(gameOutput, momentPrompt);
    const updateObject = {
      id: momentPrompt.player_moment_id ?? undefined,
      dialogue_history: updatedDialogueHistory,
      player_facts: gameOutput.player_facts,
    };

    await updatePlayerMoment(updateObject);
  };

  const manageMomentState = async (gameOutput: DTOGameOutput) => {
    const momentPrompt = momentPromptsRef.current[0];
    if (!momentPrompt) return;
    if (!momentPrompt.player_moment_id) return;
    if (!momentPrompt.player_story_id || !momentPrompt.user_id) return;

    const isMomentPlayed = playedMoments.includes(momentPrompt.player_moment_id);

    if (audioQueue.length === 0 && isMomentPlayed && gameOutput.complete_the_moment) {
      const newMoment = await createPlayerMoment({
        player_story_id: momentPrompt.player_story_id,
        blueprint_moment_id: gameOutput.next_moment_id,
        user_id: momentPrompt.user_id,
      });
      console.log("newMoment created because one ended: ", newMoment);
      setIsMomentComplete(gameOutput.complete_the_moment);
    } else if (audioQueue.length === 0 && isMomentPlayed && gameOutput.end_of_game) {
      setIsGameLost(true);
    } else {
      console.log("Moment continue: ", gameOutput);
      setObjectiveHasJustBeCompleted(gameOutput.objective_has_just_been_completed);
      setCurrentCharacterEmotion({
        emotionKey: gameOutput.character_emotion_key,
        emotionStrength: gameOutput.character_emotion_strength,
      });
    }
  };

  const getUpdatedDialogue = (
    gameOutput: DTOGameOutput,
    momentPrompt: Tables<"game_engine_view">,
  ) => {
    const history = Array.isArray(momentPrompt.dialogue_history)
      ? [...momentPrompt.dialogue_history]
      : [];
    if (gameOutput.transcribed_player_answer) {
      history.push({ player: gameOutput.transcribed_player_answer });
    }
    history.push({ llm: gameOutput.dialogue });
    return history;
  };
};

export default useMomentManager;

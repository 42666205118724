import { Tables } from "@/types/database.ts";
import {
  MOMENT_NODE_TYPES,
  MomentEdge,
  MomentNode,
  MomentType,
  MONOLOGUE,
} from "@/components/admin/storyGraph/common/types.ts";
import { MarkerType } from "@xyflow/react";
import { findById } from "@/components/admin/storyGraph/common/util.ts";

interface InitialMomentNodeProps {
  id: string;
  type: MomentType;
  position: { x: number; y: number };
  beatsheet: Tables<"blueprint_beatsheets"> | null | undefined;
  beat: Tables<"blueprint_beats"> | null | undefined;
  branch: Tables<"blueprint_branches"> | null | undefined;
  scene: Tables<"blueprint_scenes"> | null | undefined;
}

// Create an empty moment node
export function createEmptyMomentNode({
  id,
  type,
  position,
  beatsheet,
  beat,
  branch,
  scene,
}: InitialMomentNodeProps): MomentNode {
  return {
    id,
    type,
    position,
    data: {
      isInitialized: false,
      label: `New ${type}`,
      type,
      beatsheet,
      beat,
      branch,
      scene,
      moment: null,
    },
    style: { border: "none" },
  };
}

interface InitialMomentEdgeProps {
  id: string;
  source: string;
  target: string;
  label?: string;
  fromMoment: Tables<"blueprint_moments"> | null;
  toMoment: Tables<"blueprint_moments"> | null;
}

// Create an empty moment edge
export function createEmptyMomentEdge({
  id,
  source,
  target,
  label = "",
  fromMoment,
  toMoment,
}: InitialMomentEdgeProps): MomentEdge {
  const strokeColor = "#000000";

  return {
    id,
    source,
    target,
    type: "smoothstep",
    animated: false,
    label,
    data: { fromMoment, toMoment },
    style: { stroke: strokeColor },
    labelStyle: { fontSize: "12px" },
    markerEnd: {
      type: MarkerType.ArrowClosed,
      width: 10,
      height: 10,
      color: strokeColor,
    },
  };
}

// Create moment nodes from existing data
export function createMomentNodes(
  moments: Tables<"blueprint_moments">[],
  scenes: Tables<"blueprint_scenes">[],
  beatsheets: Tables<"blueprint_beatsheets">[],
  beats: Tables<"blueprint_beats">[],
  branches: Tables<"blueprint_branches">[],
): MomentNode[] {
  return moments.map((moment, index) => {
    const validType = MOMENT_NODE_TYPES[moment.moment_type]
      ? (moment.moment_type as MomentType)
      : MONOLOGUE;
    const scene = findById(scenes, moment.scene_id || "");
    const beatsheet = findById(beatsheets, scene?.beatsheet_id);
    const beat = findById(beats, beatsheet?.beat_id);
    const branch = findById(branches, scene?.branch_id);

    const node = createEmptyMomentNode({
      id: moment.id,
      type: validType,
      position: { x: 100, y: index * 100 },
      beatsheet,
      beat,
      branch,
      scene,
    });

    // Override empty node defaults with moment data
    return {
      ...node,
      data: {
        ...node.data,
        isInitialized: true,
        label: moment.moment_name || "",
        moment,
      },
    };
  });
}

// Create moment edges from existing data
export function createMomentEdges(
  transitions: Tables<"blueprint_moment_transitions">[],
  moments: Tables<"blueprint_moments">[],
): MomentEdge[] {
  return transitions.map((transition) => {
    const fromMoment = findById(moments, transition.current_moment_id);
    const toMoment = findById(moments, transition.next_moment_id);
    const label =
      transition.condition.length > 50
        ? `${transition.condition.substring(0, 50)}...`
        : transition.condition;

    return createEmptyMomentEdge({
      id: transition.id,
      source: transition.current_moment_id,
      target: transition.next_moment_id,
      label,
      fromMoment,
      toMoment,
    });
  });
}

import { useSetRecoilState } from "recoil";
import { supabase } from "@/vendor/supabaseClient";
import { useCreatePlayerStoriesAndMoments } from "@/states/storyState.tsx";
import { LOCALE_FRENCH } from "@/constants/constant.ts";
import { gameEngineModalState } from "@/states/ModalState.ts";
import { useSetupAudioContext } from "@/hooks/useSetupAudioContext.ts";

export function useGameEngineModal() {
  const setModalState = useSetRecoilState(gameEngineModalState);
  const createStoryAndMoment = useCreatePlayerStoriesAndMoments();
  const setupAudioContext = useSetupAudioContext();

  const handleMomentTest = async (storyId: string, momentId: string): Promise<void> => {
    console.log("🎮 Starting moment test setup...", { storyId, momentId });

    const {
      data: { user },
    } = await supabase.auth.getUser();
    if (!user) {
      console.error("❌ No user found - cannot proceed with moment test");
      return;
    }
    console.log("👤 User authenticated:", user.id);

    // Setup audio immediately after user interaction
    console.log("🔊 Initializing audio context...");
    await new Promise<void>((resolve) => {
      setupAudioContext(() => {
        console.log("✅ Audio context setup complete");
        resolve();
      });
    });

    console.log("📝 Creating story and moment...");
    await createStoryAndMoment(
      {
        user_id: user.id,
        blueprint_story_id: storyId,
        language: LOCALE_FRENCH,
      },
      momentId,
    );
    console.log("✅ Story and moment created successfully");

    console.log("🎯 Opening game engine modal...");
    setModalState({
      isOpen: true,
      storyId: storyId,
    });
    console.log("✅ Game engine modal opened");
  };

  return { handleMomentTest };
}

import React, { useEffect, useState } from "react";
import { Tables } from "@/types/database.ts";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { ConceptItem, SyllabusOutput } from "@/types/learning_concept_prompts_generated_types.ts";
import { TEACHING_STYLES, TeachingStyle } from "@/constants/constant.ts";
import SyllabusSubConceptSelector from "@/components/admin/syllabusManagement/SyllabusSubConceptSelector.tsx";
import {
  getSyllabusFromStoryId,
  useSyllabusDatabase,
} from "@/components/admin/syllabusManagement/useSyllabusDatabase.ts";
import { SpokableButton } from "@/components/admin/SpokableButton";
import SyllabusEditor from "@/components/admin/syllabusManagement/SyllabusEditor";

interface SelectSyllabusConceptProps {
  story: Tables<"blueprint_stories"> | null;
  syllabus: SyllabusOutput | null;
  setSyllabus: React.Dispatch<React.SetStateAction<SyllabusOutput | null>>;
  selectedSyllabusConcept: ConceptItem | null;
  setSelectedSyllabusConcept: React.Dispatch<React.SetStateAction<ConceptItem | null>>;
  selectedTeachingStyle: TeachingStyle;
  setSelectedTeachingStyle: React.Dispatch<React.SetStateAction<TeachingStyle>>;
  allMoments: Tables<"blueprint_moments">[] | null;
}

const SelectSyllabusConcept: React.FC<SelectSyllabusConceptProps> = ({
  story,
  selectedTeachingStyle,
  setSelectedTeachingStyle,
  selectedSyllabusConcept,
  setSelectedSyllabusConcept,
  syllabus,
  setSyllabus,
  allMoments,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const { upsertSyllabus } = useSyllabusDatabase();

  useEffect(() => {
    async function fetchSyllabus() {
      if (!story) return;
      const syllabus = await getSyllabusFromStoryId(story.id);
      if (syllabus) setSyllabus(syllabus);
    }

    fetchSyllabus();
  }, [story]);

  const handleSaveAndClose = async () => {
    console.log("Saving syllabus");
    if (!syllabus || !story) return;
    console.log("Saving syllabus2");
    try {
      const success = await upsertSyllabus(syllabus, story.id);
      if (success) {
        setErrorMessage(null);
        setIsEditing(false);
      } else {
        setErrorMessage("Failed to save syllabus");
      }
    } catch (error) {
      setErrorMessage(`Error saving syllabus: ${error}`);
    }
  };

  if (isEditing && syllabus) {
    return (
      <div className="w-full max-w-4xl mx-auto p-6">
        <div className="flex items-center justify-between mb-6">
          <h3 className="text-2xl font-bold">Edit Syllabus</h3>
          <div className="flex gap-2">
            <SpokableButton onClick={handleSaveAndClose}>Save & Close</SpokableButton>
            <SpokableButton color="light" onClick={() => setIsEditing(false)}>
              Cancel
            </SpokableButton>
          </div>
        </div>
        {errorMessage && (
          <div className="bg-red-50 border border-red-200 text-red-700 px-4 py-3 rounded mb-4">
            {errorMessage}
          </div>
        )}
        <div className="border rounded-lg p-4 bg-white">
          <SyllabusEditor syllabus={syllabus} setSyllabus={setSyllabus} />
        </div>
      </div>
    );
  }

  return (
    <div className="w-full max-w-4xl mx-auto p-6">
      <div className="flex items-center justify-between mb-6">
        <h3 className="text-2xl font-bold">Select a concept to teach</h3>
        {syllabus && (
          <SpokableButton color="light" onClick={() => setIsEditing(true)}>
            Edit Syllabus
          </SpokableButton>
        )}
      </div>
      <div className="space-y-6">
        <div className="w-full">
          <label className="block text-sm font-medium mb-2">Scene Generation Instructions</label>
          <Select
            onValueChange={(value) => setSelectedTeachingStyle(value as TeachingStyle)}
            value={selectedTeachingStyle}
          >
            <SelectTrigger className="w-full">
              <SelectValue placeholder="Choose a teaching style..." />
            </SelectTrigger>
            <SelectContent>
              {TEACHING_STYLES.map((style) => (
                <SelectItem key={style.key} value={style.key}>
                  {style.value}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
        <div className="w-full border-zinc-200 border rounded-lg p-4">
          {syllabus && (
            <SyllabusSubConceptSelector
              allMoments={allMoments}
              syllabus={syllabus}
              setSyllabus={setSyllabus}
              selectedSyllabusConcept={selectedSyllabusConcept}
              setSelectedSyllabusConcept={setSelectedSyllabusConcept}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default SelectSyllabusConcept;

// src/components/admin/storyGraph/sceneLevel/nodes/TeachingNode.tsx
import React from "react";
import { NodeProps } from "@xyflow/react";

import { BookOpen } from "lucide-react";
import { BaseNode } from "@/components/admin/storyGraph/common/BaseNode.tsx";
import { MomentNode } from "@/components/admin/storyGraph/common/types.ts";

const LearningNode: React.FC<NodeProps<MomentNode>> = (props) => {
  return (
    <BaseNode {...props}>
      <div className="flex items-center justify-center gap-2 relative">
        <BookOpen className="w-5 h-5" />
        <span>{props.data.label}</span>
      </div>
    </BaseNode>
  );
};

export default LearningNode;

import { SceneEdgeData, SceneNodeData } from "@/components/admin/storyGraph/common/types.ts";
import { Tables } from "@/types/database.ts";
import { Edge, Node } from "@xyflow/react";
import { MomentEdgeData, MomentNodeData } from "@/components/admin/storyGraph/common/types";

/**
 * Returns an ordered array of `scene` objects (Tables<"blueprint_scenes">) from
 * the **first root node** (the first node with no incoming edges) up to (but NOT including)
 * the `targetNodeId`.
 *
 * @param nodes - The array of React Flow nodes (each with an `id` and possibly `data.scene`).
 * @param edges - The array of React Flow edges (each with `source` and `target`).
 * @param targetNodeId - The ID of the target node (UUID or any string).
 * @returns An array of `BlueprintScene` from the first root node to right before the target node.
 *          If no path is found or no root exists, returns an empty array.
 */
export function getScenesPathToNode(
  nodes: Node<SceneNodeData>[],
  edges: Edge<SceneEdgeData>[],
  targetNodeId: string | undefined,
): Tables<"blueprint_scenes">[] {
  if (!nodes || !edges) return [];

  // 1) Identify the first node with no incoming edges (the "root" node)
  const rootNode = nodes.find((node) => {
    const hasIncomingEdge = edges.some((edge) => edge.target === node.id);
    return !hasIncomingEdge;
  });

  // If no root node is found, return an empty array
  if (!rootNode) {
    return [];
  }

  const rootNodeId = rootNode.id;

  // 2) Build a Map for quick lookups (id -> Node)
  const nodeById = new Map<string, Node<SceneNodeData>>(nodes.map((n) => [n.id, n]));

  // 3) Create adjacency list for BFS
  //    adjacencyList[sourceId] = array of targetIds
  const adjacencyList = new Map<string, string[]>();
  for (const edge of edges) {
    if (!adjacencyList.has(edge.source)) {
      adjacencyList.set(edge.source, []);
    }
    adjacencyList.get(edge.source)!.push(edge.target);
  }

  // 4) BFS from `rootNodeId` to `targetNodeId`
  const queue: string[] = [rootNodeId];
  // This map tells us how we reached each node (child -> parent)
  const parentMap = new Map<string, string | null>([[rootNodeId, null]]);

  let found = false;

  while (queue.length > 0) {
    const currentId = queue.shift()!;
    if (currentId === targetNodeId) {
      found = true;
      break;
    }

    const neighbors = adjacencyList.get(currentId) || [];
    for (const neighborId of neighbors) {
      if (!parentMap.has(neighborId)) {
        parentMap.set(neighborId, currentId);
        queue.push(neighborId);
      }
    }
  }

  if (!found) {
    // No path from root to target
    return [];
  }

  // 5) Reconstruct path from `targetNodeId` back to `rootNodeId`
  const pathIds: string[] = [];
  let current: string | undefined | null = targetNodeId;
  while (current) {
    pathIds.push(current);
    current = parentMap.get(current) || null;
  }

  // That yields [targetNodeId, ..., rootNodeId], so reverse it
  pathIds.reverse(); // => [rootNodeId, ..., targetNodeId]

  // Remove the target node from the path so its scene is NOT included
  pathIds.pop(); // => [rootNodeId, ..., nodeBeforeTarget]

  // 6) Convert node IDs to their `scene` objects (BlueprintScene)
  const scenesPath: Tables<"blueprint_scenes">[] = [];
  for (const nodeId of pathIds) {
    const node = nodeById.get(nodeId);
    // Only push if node has a scene
    if (node?.data?.scene) {
      scenesPath.push(node.data.scene);
    }
  }

  return scenesPath;
}

/**
 * Returns an ordered array of moment objects (Tables<"blueprint_moments">) from
 * the first root moment node (the first node with no incoming edges) up to (but NOT including)
 * the `targetNodeId`.
 *
 * @param nodes - The array of React Flow moment nodes (each with an `id` and possibly `data.moment`).
 * @param edges - The array of React Flow edges (each with `source` and `target`).
 * @param targetNodeId - The ID of the target node (UUID or any string).
 * @returns An array of `BlueprintMoment` from the first root node to right before the target node.
 *          If no path is found or no root exists, returns an empty array.
 */
export function getMomentsPathToNode(
  nodes: Node<MomentNodeData>[],
  edges: Edge<MomentEdgeData>[],
  targetNodeId: string | undefined,
): Tables<"blueprint_moments">[] {
  if (!nodes || !edges || !targetNodeId) return [];

  // 1) Find the root moment node (no incoming edges)
  const rootNode = nodes.find((node) => {
    const hasIncomingEdge = edges.some((edge) => edge.target === node.id);
    return !hasIncomingEdge;
  });

  if (!rootNode) return [];

  const rootNodeId = rootNode.id;

  // 2) Create a Map for quick node lookups
  const nodeById = new Map<string, Node<MomentNodeData>>(nodes.map((node) => [node.id, node]));

  // 3) Create adjacency list for traversal
  const adjacencyList = new Map<string, string[]>();
  edges.forEach((edge) => {
    if (!adjacencyList.has(edge.source)) {
      adjacencyList.set(edge.source, []);
    }
    adjacencyList.get(edge.source)!.push(edge.target);
  });

  // 4) BFS traversal from root to target
  const queue: string[] = [rootNodeId];
  const parentMap = new Map<string, string | null>([[rootNodeId, null]]);
  let found = false;

  while (queue.length > 0) {
    const currentId = queue.shift()!;

    if (currentId === targetNodeId) {
      found = true;
      break;
    }

    const neighbors = adjacencyList.get(currentId) || [];
    for (const neighborId of neighbors) {
      if (!parentMap.has(neighborId)) {
        parentMap.set(neighborId, currentId);
        queue.push(neighborId);
      }
    }
  }

  if (!found) return [];

  // 5) Reconstruct the path
  const pathIds: string[] = [];
  let current: string | null = targetNodeId;

  while (current) {
    pathIds.push(current);
    current = parentMap.get(current) || null;
  }

  // Reverse to get correct order and remove target node
  pathIds.reverse();
  pathIds.pop();

  // 6) Map node IDs to their moment objects
  const momentsPath: Tables<"blueprint_moments">[] = [];

  for (const nodeId of pathIds) {
    const node = nodeById.get(nodeId);
    if (node?.data?.moment) {
      momentsPath.push(node.data.moment);
    }
  }

  return momentsPath;
}

/**
 * Finds the best candidate moment for creating a transition to the current moment.
 * Looks for the last moment in either the current scene or the previous scene.
 * Handles cases where it might be the first scene or when scenes/transitions are null.
 *
 * @param currentMoment - The current moment we want to create a transition to
 * @param allMoments - All moments in the story (can be null/undefined)
 * @param allScenes - All scenes in the story (can be null/undefined)
 * @param existingTransitions - All existing moment transitions (can be null/undefined)
 * @returns The best candidate moment for creating a transition, or undefined if none found
 */
export function findPreviousMoment(
  currentMoment: Tables<"blueprint_moments">,
  allMoments: Tables<"blueprint_moments">[] | null | undefined,
  allScenes: Tables<"blueprint_scenes">[] | null | undefined,
  existingTransitions: Tables<"blueprint_moment_transitions">[] | null | undefined,
): Tables<"blueprint_moments"> | undefined {
  // Guard against null/undefined parameters
  const moments = allMoments || [];
  const scenes = allScenes || [];
  const transitions = existingTransitions || [];

  // Early return if current moment is a starting moment
  if (currentMoment.is_starting_moment) {
    return undefined;
  }

  // Safely get moments in current scene if it exists
  if (currentMoment.scene_id) {
    const currentSceneMoments = moments.filter((moment) => {
      // Must be in same scene but not the current moment
      const isValidMoment =
        moment.scene_id === currentMoment.scene_id && moment.id !== currentMoment.id;

      if (!isValidMoment) return false;

      // Check if this moment has any outgoing transitions
      const hasOutgoingTransitions = transitions.some((t) => t.current_moment_id === moment.id);

      // Check if there's already a transition to our current moment
      const hasTransitionToCurrent = transitions.some(
        (t) => t.current_moment_id === moment.id && t.next_moment_id === currentMoment.id,
      );

      // We want moments that either:
      // 1. Have no outgoing transitions (dead ends)
      // 2. Don't have a transition to our current moment
      return !hasOutgoingTransitions || !hasTransitionToCurrent;
    });

    // If we found valid moments in the current scene, return the most suitable one
    if (currentSceneMoments.length > 0) {
      return currentSceneMoments[currentSceneMoments.length - 1];
    }
  }

  // If we reach here, either:
  // 1. There's no current scene
  // 2. Or there are no suitable moments in the current scene
  // Try to find a previous scene if we have scene information

  const currentScene = currentMoment.scene_id
    ? scenes.find((scene) => scene.id === currentMoment.scene_id)
    : undefined;

  if (currentScene) {
    // Find the previous scene based on scene_order
    const previousScene = scenes
      .filter(
        (scene) =>
          scene.beatsheet_id === currentScene.beatsheet_id &&
          scene.branch_id === currentScene.branch_id &&
          scene.scene_order < currentScene.scene_order,
      )
      .sort((a, b) => b.scene_order - a.scene_order)[0];

    if (previousScene) {
      // Get moments from the previous scene
      const previousSceneMoments = moments.filter((moment) => {
        const isInPreviousScene = moment.scene_id === previousScene.id;
        if (!isInPreviousScene) return false;

        // Check for existing transition to current moment
        const hasTransitionToCurrent = transitions.some(
          (t) => t.current_moment_id === moment.id && t.next_moment_id === currentMoment.id,
        );

        return !hasTransitionToCurrent;
      });

      if (previousSceneMoments.length > 0) {
        return previousSceneMoments[previousSceneMoments.length - 1];
      }
    }
  }

  // If we reach here, we're either:
  // 1. In the first scene
  // 2. Or dealing with moments without scenes
  // 3. Or couldn't find suitable moments in previous scene

  // Look for any starting moments in the story that don't already transition to our moment
  const startingMoments = moments.filter(
    (moment) =>
      moment.is_starting_moment &&
      moment.id !== currentMoment.id &&
      !transitions.some(
        (t) => t.current_moment_id === moment.id && t.next_moment_id === currentMoment.id,
      ),
  );

  if (startingMoments.length > 0) {
    return startingMoments[0];
  }

  // If we still haven't found anything, return undefined
  return undefined;
}

import { DragEvent, useCallback, useEffect } from "react";
import { useEdgesState, useNodesState, useReactFlow } from "@xyflow/react";
import { useConnectionHandling } from "@/components/admin/storyGraph/layout/useConnectionHandling";
import { useFlowLayout } from "@/components/admin/storyGraph/layout/useFlowLayout";
import { Tables } from "@/types/database";
import {
  SCENE_NODE_TYPES,
  SceneEdge,
  SceneNode,
  SceneType,
} from "@/components/admin/storyGraph/common/types";
import {
  createEmptySceneEdge,
  createEmptySceneNode,
  createSceneEdges,
  createSceneNodes,
} from "@/components/admin/storyGraph/sceneLevel/SceneNodeEdgeFactories.ts";

interface SceneFlowProps {
  scenes: Tables<"blueprint_scenes">[];
  beatsheets: Tables<"blueprint_beatsheets">[];
  beats: Tables<"blueprint_beats">[];
  branches: Tables<"blueprint_branches">[];
}

export function useSceneFlow({ scenes, beatsheets, beats, branches }: SceneFlowProps) {
  const [sceneNodes, setSceneNodes, onSceneNodesChange] = useNodesState<SceneNode>([]);
  const [sceneEdges, setSceneEdges, onSceneEdgesChange] = useEdgesState<SceneEdge>([]);

  const { performLayout } = useFlowLayout();
  const { screenToFlowPosition } = useReactFlow();

  // Initialize scene graph
  useEffect(() => {
    const initialSceneNodes = createSceneNodes(scenes, beatsheets, beats, branches);
    const initialSceneEdges = createSceneEdges(scenes, beatsheets, beats, branches);
    const layoutedSceneNodes = performLayout(initialSceneNodes, initialSceneEdges);
    setSceneNodes(layoutedSceneNodes);
    setSceneEdges(initialSceneEdges);
  }, [scenes, beatsheets, beats, branches]);

  // Scene level connection handling
  const {
    onConnect: onSceneConnect,
    findNodeAtPosition: findSceneAtPosition,
    handleNodeInsertion: handleSceneInsertion,
  } = useConnectionHandling({
    nodes: sceneNodes,
    edges: sceneEdges,
    setEdges: setSceneEdges,
    createEmptyEdge: (source, target) =>
      createEmptySceneEdge({
        id: `e${source}-${target}`,
        source,
        target,
        fromBeatsheet: null,
        toBeatsheet: null,
        fromBeat: null,
        toBeat: null,
        branch: null,
        fromScene: null,
        toScene: null,
        isIntraBeatsheet: true,
      }),
  });

  const onScenesDrop = useCallback(
    (event: DragEvent<HTMLDivElement>) => {
      event.preventDefault();
      const type = event.dataTransfer.getData("application/reactflow") as SceneType;
      if (!Object.keys(SCENE_NODE_TYPES).includes(type)) return;

      const position = screenToFlowPosition({
        x: event.clientX,
        y: event.clientY,
      });

      const newNodeId = `${type}-${sceneNodes.length + 1}`;
      const newNode = createEmptySceneNode({
        id: newNodeId,
        position,
        sceneOrder: sceneNodes.length + 1,
        beatsheet: null,
        beat: null,
        branch: null,
        scene: null,
      });

      const targetNode = findSceneAtPosition(position);
      const newEdges = handleSceneInsertion(targetNode, newNode.id);
      const updatedNodes = [...sceneNodes, newNode];
      const layoutedNodes = performLayout(updatedNodes, newEdges) as SceneNode[];

      setSceneNodes(layoutedNodes);
      setSceneEdges(newEdges as SceneEdge[]);
    },
    [sceneNodes, screenToFlowPosition, findSceneAtPosition, handleSceneInsertion, performLayout],
  );

  const onScenesLayout = useCallback(() => {
    const layoutedNodes = performLayout(sceneNodes, sceneEdges);
    setSceneNodes(layoutedNodes);
  }, [sceneNodes, sceneEdges, performLayout]);

  const updateSceneNode = useCallback(
    (nodeId: string, newData: Partial<SceneNode>) => {
      setSceneNodes((nds) =>
        nds.map((node) => (node.id === nodeId ? ({ ...node, ...newData } as SceneNode) : node)),
      );
    },
    [setSceneNodes],
  );

  return {
    sceneNodes,
    sceneEdges,
    onSceneNodesChange,
    onSceneEdgesChange,
    onSceneConnect,
    onScenesDrop,
    onScenesLayout,
    updateSceneNode,
  };
}

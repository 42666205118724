import { useCallback, useState } from "react";
import { useSetRecoilState } from "recoil";
import { audioContextState } from "../states/audioDataState";

interface WindowWithWebkitAudioContext extends Window {
  webkitAudioContext?: typeof AudioContext;
}

export function useSetupAudioContext() {
  const setAudioContext = useSetRecoilState<AudioContext | null>(audioContextState);
  const [audioInitiated, setAudioInitiated] = useState<boolean>(false);

  return useCallback(
    (callback?: () => void) => {
      if (!audioInitiated) {
        console.log("🎵 Creating new AudioContext...");
        const audioContext = new (window.AudioContext ||
          (window as WindowWithWebkitAudioContext).webkitAudioContext)();
        setAudioContext(audioContext);
        setAudioInitiated(true);

        console.log("🔓 Attempting to unlock audio context...");
        fetch("/silent.mp3")
          .then((response) => response.arrayBuffer())
          .then((arrayBuffer) => audioContext.decodeAudioData(arrayBuffer))
          .then((decodedAudio) => {
            const source = audioContext.createBufferSource();
            source.buffer = decodedAudio;
            source.connect(audioContext.destination);
            source.start(0);
            console.log("🔊 Audio context unlocked successfully");
          })
          .catch((e) => console.error("❌ Error unlocking audio:", e));
      } else {
        console.log("ℹ️ Audio context already initiated");
      }
      if (callback) {
        console.log("📞 Executing audio setup callback");
        callback();
      }
    },
    [setAudioContext, audioInitiated],
  );
}

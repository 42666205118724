import React, { useMemo } from "react";
import { Card, CardContent } from "@/components/ui/card";
import { Badge } from "@/components/ui/badge";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";
import { HoverCard, HoverCardContent, HoverCardTrigger } from "@/components/ui/hover-card";
import { AlertCircle, CheckCircle2 } from "lucide-react";
import type {
  ConceptItem,
  LearningPathElement,
  SyllabusOutput,
} from "@/types/learning_concept_prompts_generated_types";
import { useGroupedItems } from "@/components/admin/syllabusManagement/useSyllabusEditor";
import { Tables } from "@/types/database.ts";

interface SyllabusSubConceptSelectorProps {
  syllabus: SyllabusOutput | null;
  setSyllabus: React.Dispatch<React.SetStateAction<SyllabusOutput | null>>;
  selectedSyllabusConcept: ConceptItem | null;
  setSelectedSyllabusConcept: React.Dispatch<React.SetStateAction<ConceptItem | null>>;
  allMoments: Tables<"blueprint_moments">[] | null;
}

const SyllabusSubConceptSelector: React.FC<SyllabusSubConceptSelectorProps> = ({
  syllabus,
  setSyllabus,
  selectedSyllabusConcept,
  setSelectedSyllabusConcept,
  allMoments,
}) => {
  // Always call hooks at the top level with empty array fallback
  const itemsByConceptId = useGroupedItems(syllabus?.items ?? []);

  const momentsBySubconceptId = useMemo(() => {
    if (!allMoments) return new Map<string, Tables<"blueprint_moments">>();

    const map = new Map<string, Tables<"blueprint_moments">>();
    allMoments.forEach((moment) => {
      if (moment.blueprint_learning_sub_concept_id) {
        map.set(moment.blueprint_learning_sub_concept_id, moment);
      }
    });
    return map;
  }, [allMoments]);

  const arePrerequisitesFulfilled = (concept: LearningPathElement): boolean => {
    if (!concept.prerequisites || concept.prerequisites.length === 0) return true;

    return concept.prerequisites.every((prereqConceptId) => {
      const prereqSubconcepts = itemsByConceptId[prereqConceptId] || [];
      // Check if ALL subconcepts of the prerequisite concept have moments
      return (
        prereqSubconcepts.length > 0 &&
        prereqSubconcepts.every((subconcept) => {
          return momentsBySubconceptId.has(subconcept.sub_concept_id);
        })
      );
    });
  };

  const getMissingPrerequisites = (concept: LearningPathElement) => {
    if (!concept.prerequisites || concept.prerequisites.length === 0) return [];

    return concept.prerequisites
      .filter((prereqId) => {
        const prereqSubconcepts = itemsByConceptId[prereqId] || [];
        // A prerequisite is missing if ANY of its subconcepts don't have moments
        return (
          prereqSubconcepts.length === 0 ||
          prereqSubconcepts.some(
            (subconcept) => !momentsBySubconceptId.has(subconcept.sub_concept_id),
          )
        );
      })
      .map(
        (prereqId) => syllabus?.learning_path.find((c) => c.concept_id === prereqId)?.concept_name,
      )
      .filter(Boolean) as string[];
  };

  const handleSelectSubconcept = (item: ConceptItem, concept: LearningPathElement): void => {
    // Don't allow selection if prerequisites are not met or if the subconcept is already covered
    if (!arePrerequisitesFulfilled(concept) || momentsBySubconceptId.has(item.sub_concept_id)) {
      return;
    }

    setSelectedSyllabusConcept(item);
    setSyllabus((prev) => {
      if (!prev) return null;
      return {
        ...prev,
        selectedSubconceptId: `${item.concept_id}-${item.sub_concept_id}`,
      } as SyllabusOutput & { selectedSubconceptId?: string };
    });
  };

  if (!syllabus) {
    return null;
  }

  return (
    <div className="max-w-4xl mx-auto space-y-4">
      <label className="block text-sm font-medium mb-2">{syllabus.subject}</label>
      <Accordion type="single" collapsible className="space-y-2">
        {syllabus.learning_path.map((concept: LearningPathElement) => {
          const subconcepts = itemsByConceptId[concept.concept_id] || [];
          if (subconcepts.length === 0) return null;

          const prerequisitesMet = arePrerequisitesFulfilled(concept);
          const missingPrereqs = getMissingPrerequisites(concept);
          const hasPrerequisites = concept.prerequisites && concept.prerequisites.length > 0;

          return (
            <AccordionItem key={concept.concept_id} value={concept.concept_id}>
              <AccordionTrigger className="rounded-lg px-4">
                <div className="flex items-center gap-2">
                  <span>{concept.concept_name}</span>
                  {hasPrerequisites && (
                    <HoverCard>
                      <HoverCardTrigger>
                        <Badge
                          variant={prerequisitesMet ? "secondary" : "destructive"}
                          className="ml-2"
                        >
                          {prerequisitesMet
                            ? `${concept.prerequisites?.length} prerequisites met`
                            : `${missingPrereqs.length} prerequisites needed`}
                        </Badge>
                      </HoverCardTrigger>
                      <HoverCardContent className="w-96 p-4 bg-white rounded-xl shadow-lg">
                        <div className="space-y-3">
                          <div className="flex items-center gap-2 pb-2 border-b">
                            <h4 className="text-lg font-semibold">Prerequisites</h4>
                          </div>
                          {prerequisitesMet ? (
                            <div className="flex items-center gap-2 p-2 bg-green-50 rounded-lg">
                              <p className="text-sm text-green-700 font-medium">
                                ✓ All prerequisites are met
                              </p>
                            </div>
                          ) : (
                            <div className="space-y-2">
                              <p className="text-sm font-medium text-red-600">
                                Missing prerequisites:
                              </p>
                              <ul className="space-y-1">
                                {missingPrereqs.map((prereq, index) => (
                                  <li
                                    key={index}
                                    className="flex items-center gap-2 text-sm text-gray-700 pl-2"
                                  >
                                    • {prereq}
                                  </li>
                                ))}
                              </ul>
                            </div>
                          )}
                        </div>
                      </HoverCardContent>
                    </HoverCard>
                  )}
                </div>
              </AccordionTrigger>
              <AccordionContent className="px-4">
                <div className="grid gap-2">
                  {subconcepts.map((item: ConceptItem) => {
                    const itemId = `${item.concept_id}-${item.sub_concept_id}`;
                    const isSelected =
                      selectedSyllabusConcept?.concept_id === item.concept_id &&
                      selectedSyllabusConcept?.sub_concept_id === item.sub_concept_id;
                    const isCovered = momentsBySubconceptId.has(item.sub_concept_id);

                    return (
                      <Card
                        key={itemId}
                        className={`border transition-all duration-200
                          ${!prerequisitesMet || isCovered ? "bg-gray-50 cursor-not-allowed" : "hover:border-black cursor-pointer"}
                          ${isSelected ? "bg-black text-white" : ""}
                        `}
                        onClick={() => handleSelectSubconcept(item, concept)}
                      >
                        <CardContent className="p-4 flex justify-between items-center">
                          <div className="flex items-center gap-2">
                            <h5 className="font-medium">{item.name}</h5>
                            {!prerequisitesMet && <AlertCircle className="h-4 w-4 text-red-500" />}
                            {isCovered && (
                              <HoverCard>
                                <HoverCardTrigger>
                                  <Badge variant="secondary" className="flex items-center gap-1">
                                    <CheckCircle2 className="h-3 w-3" />
                                    Covered
                                  </Badge>
                                </HoverCardTrigger>
                                <HoverCardContent className="w-96 p-4 bg-white rounded-xl shadow-lg">
                                  <div className="space-y-3">
                                    <div className="flex items-center gap-2 pb-2 border-b">
                                      <h4 className="text-lg font-semibold">Moment Details</h4>
                                    </div>
                                    {(() => {
                                      const moment = momentsBySubconceptId.get(item.sub_concept_id);
                                      return moment ? (
                                        <div className="space-y-2">
                                          <div className="flex items-center gap-2">
                                            <Badge variant="outline">{moment.moment_type}</Badge>
                                            <span className="text-sm font-medium">
                                              {moment.moment_name || "Unnamed moment"}
                                            </span>
                                          </div>
                                          {moment.moment_setting && (
                                            <p className="text-sm text-gray-600">
                                              Setting: {moment.moment_setting}
                                            </p>
                                          )}
                                        </div>
                                      ) : null;
                                    })()}
                                  </div>
                                </HoverCardContent>
                              </HoverCard>
                            )}
                          </div>
                          <div className="flex items-center gap-2">
                            <Badge>{item.noun}</Badge>
                            <Badge className="bg-white" variant="outline">
                              {item.verb}
                            </Badge>
                            <Badge variant="secondary">{item.adjective}</Badge>
                          </div>
                        </CardContent>
                      </Card>
                    );
                  })}
                </div>
              </AccordionContent>
            </AccordionItem>
          );
        })}
      </Accordion>
    </div>
  );
};

export default SyllabusSubConceptSelector;

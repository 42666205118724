import React from "react";
import { SpokableButton } from "./SpokableButton.tsx";

interface SaveAllStepUIProps {
  onSave: () => Promise<void>;
  errorMessage?: string | null;
}

const SaveAllStepUI: React.FC<SaveAllStepUIProps> = ({ onSave, errorMessage = null }) => {
  return (
    <div className="w-full max-w-4xl mx-auto p-6 max-h-[70vh] flex flex-col">
      <div className="flex flex-col">
        <div className="flex-grow">
          {errorMessage && <div className="mb-4 whitespace-pre-wrap">{errorMessage}</div>}
          <img src="/spokable_logo.svg" alt="logo" />
          <SpokableButton onClick={onSave} className="mt-6 mb-6">
            Save all
          </SpokableButton>
        </div>
      </div>
    </div>
  );
};

export default SaveAllStepUI;

import {
  ConceptItem,
  LearningPathElement,
  SyllabusOutput,
} from "@/types/learning_concept_prompts_generated_types.ts";
import { supabase } from "@/vendor/supabaseClient.ts";
import { TablesInsert } from "@/types/database.ts";
import { v4 as uuidv4 } from "uuid";
import { isValidUUID } from "@/utils/uuid.ts";

const convertSyllabusToUuid = (syllabus: SyllabusOutput): SyllabusOutput => {
  // Create a mapping of old IDs to new UUIDs
  const idMapping: Record<string, string> = {};

  // Convert learning path concept IDs
  const newLearningPath = syllabus.learning_path.map((concept) => {
    // If the concept_id is a valid UUID, keep it
    if (isValidUUID(concept.concept_id)) {
      idMapping[concept.concept_id] = concept.concept_id;
      return concept;
    }

    const newId = idMapping[concept.concept_id] || uuidv4();
    idMapping[concept.concept_id] = newId;

    return {
      ...concept,
      concept_id: newId,
      prerequisites: concept.prerequisites?.map((prereqId) => {
        // If we've already mapped this prerequisite ID, use it
        if (idMapping[prereqId]) {
          return idMapping[prereqId];
        }
        // If it's a valid UUID, keep it
        if (isValidUUID(prereqId)) {
          idMapping[prereqId] = prereqId;
          return prereqId;
        }
        // Otherwise generate new
        idMapping[prereqId] = uuidv4();
        return idMapping[prereqId];
      }),
    };
  });

  // Convert items (sub-concepts) IDs
  const newItems = syllabus.items.map((item) => {
    // If the sub_concept_id is a valid UUID, keep it
    const keepExistingSubConcept = isValidUUID(item.sub_concept_id);
    const newSubConceptId = keepExistingSubConcept ? item.sub_concept_id : uuidv4();

    // Use the already mapped concept ID from above or keep existing if it's a valid UUID
    const newConceptId =
      idMapping[item.concept_id] || (isValidUUID(item.concept_id) ? item.concept_id : uuidv4());

    if (!newConceptId) {
      throw new Error(`Missing concept ID mapping for ${item.concept_id}`);
    }

    return {
      ...item,
      concept_id: newConceptId,
      sub_concept_id: newSubConceptId,
      prerequisites: item.prerequisites?.map((prereqId) => {
        if (idMapping[prereqId]) {
          return idMapping[prereqId];
        }
        if (isValidUUID(prereqId)) {
          idMapping[prereqId] = prereqId;
          return prereqId;
        }
        idMapping[prereqId] = uuidv4();
        return idMapping[prereqId];
      }),
    };
  });

  return {
    ...syllabus,
    items: newItems,
    learning_path: newLearningPath,
  };
};
export const getSyllabusFromDb = async (subjectId: string): Promise<SyllabusOutput | null> => {
  try {
    // Get subject
    const { data: subject, error: subjectError } = await supabase
      .from("blueprint_learning_subjects")
      .select("*")
      .eq("id", subjectId)
      .single();

    if (subjectError || !subject) {
      throw new Error(`Failed to fetch subject: ${subjectError?.message}`);
    }

    // Get all concepts for this subject
    const { data: concepts, error: conceptsError } = await supabase
      .from("blueprint_learning_concepts")
      .select("*")
      .eq("subject_id", subjectId)
      .order("concept_order");

    if (conceptsError || !concepts) {
      throw new Error(`Failed to fetch concepts: ${conceptsError?.message}`);
    }

    // Get all sub-concepts for these concepts
    const { data: subConcepts, error: subConceptsError } = await supabase
      .from("blueprint_learning_sub_concepts")
      .select("*")
      .in(
        "concept_id",
        concepts.map((c) => c.id),
      )
      .order("sub_concept_order");

    if (subConceptsError || !subConcepts) {
      throw new Error(`Failed to fetch sub-concepts: ${subConceptsError?.message}`);
    }

    // Transform to SyllabusOutput format
    const items: ConceptItem[] = subConcepts.map((sc) => ({
      concept_id: sc.concept_id,
      sub_concept_id: sc.id,
      name: sc.name,
      order: sc.sub_concept_order,
      noun: sc.noun || "",
      verb: sc.verb || "",
      adjective: sc.adjective || "",
      prerequisites: [], // If you need to implement sub-concept prerequisites
    }));

    const learning_path: LearningPathElement[] = concepts.map((c) => ({
      concept_id: c.id,
      concept_name: c.concept_name,
      learning_summary: c.learning_summary || "",
      prerequisites: c.prerequisite_concept_id ? [c.prerequisite_concept_id] : [],
      learning_outcomes: c.learning_outcomes || [],
      practical_exercises: c.practical_exercises || [],
    }));

    return {
      subject: subject.name,
      items,
      learning_path,
    };
  } catch (error) {
    console.error("Error fetching syllabus:", error);
    return null;
  }
};

// Function to insert/update complete syllabus into database
export const upsertSyllabusToDb = async (
  syllabus: SyllabusOutput,
  storyId: string,
): Promise<boolean> => {
  try {
    // Convert all string IDs to UUIDs before upserting
    const uuidSyllabus = convertSyllabusToUuid(syllabus);

    // First, check if a subject already exists for this story
    const { data: existingSubject } = await supabase
      .from("blueprint_learning_subjects")
      .select("id")
      .eq("story_id", storyId)
      .limit(1)
      .single();

    // Start a Supabase transaction
    const { data: subject, error: subjectError } = await supabase
      .from("blueprint_learning_subjects")
      .upsert({
        id: existingSubject?.id || uuidv4(), // Use existing ID if available, otherwise generate new
        name: uuidSyllabus.subject,
        story_id: storyId,
      })
      .select()
      .single();

    if (subjectError || !subject) {
      throw new Error(`Failed to upsert subject: ${subjectError?.message}`);
    }

    // Insert/update concepts with new UUIDs
    const conceptInserts: TablesInsert<"blueprint_learning_concepts">[] =
      uuidSyllabus.learning_path.map((concept, index) => ({
        id: concept.concept_id, // Using our pre-generated UUID
        subject_id: subject.id,
        concept_name: concept.concept_name,
        learning_summary: concept.learning_summary,
        concept_order: index + 1,
        learning_outcomes: concept.learning_outcomes,
        practical_exercises: concept.practical_exercises,
        prerequisite_concept_id: concept.prerequisites?.[0] || null,
      }));

    const { error: conceptsError } = await supabase
      .from("blueprint_learning_concepts")
      .upsert(conceptInserts);

    if (conceptsError) {
      throw new Error(`Failed to upsert concepts: ${conceptsError.message}`);
    }

    // Insert/update sub-concepts with new UUIDs
    const subConceptInserts: TablesInsert<"blueprint_learning_sub_concepts">[] =
      uuidSyllabus.items.map((item) => ({
        id: item.sub_concept_id, // Using our pre-generated UUID
        concept_id: item.concept_id,
        name: item.name,
        sub_concept_order: item.order,
        noun: item.noun,
        verb: item.verb,
        adjective: item.adjective,
      }));

    const { error: subConceptsError } = await supabase
      .from("blueprint_learning_sub_concepts")
      .upsert(subConceptInserts);

    if (subConceptsError) {
      throw new Error(`Failed to upsert sub-concepts: ${subConceptsError.message}`);
    }

    return true;
  } catch (error) {
    console.error("Error upserting syllabus:", error);
    return false;
  }
};

// Function to delete a complete syllabus and all its related data
export const deleteSyllabusFromDb = async (subjectId: string): Promise<boolean> => {
  try {
    // Thanks to CASCADE DELETE in our schema, we only need to delete the subject
    const { error } = await supabase
      .from("blueprint_learning_subjects")
      .delete()
      .eq("id", subjectId);

    if (error) {
      throw new Error(`Failed to delete syllabus: ${error.message}`);
    }

    return true;
  } catch (error) {
    console.error("Error deleting syllabus:", error);
    return false;
  }
};

export const getSyllabusFromStoryId = async (storyId: string): Promise<SyllabusOutput | null> => {
  try {
    console.log("story_id", storyId);
    const { data: subject, error: subjectError } = await supabase
      .from("blueprint_learning_subjects")
      .select("*")
      .eq("story_id", storyId)
      .limit(1)
      .single();

    if (subjectError || !subject) {
      return null;
    }

    // Use the subject.id to retrieve the full syllabus.
    return await getSyllabusFromDb(subject.id);
  } catch (error) {
    console.error("Error fetching syllabus by story id:", error);
    return null;
  }
};

export const useSyllabusDatabase = () => {
  const getSyllabus = async (subjectId: string) => {
    return await getSyllabusFromDb(subjectId);
  };

  const upsertSyllabus = async (syllabus: SyllabusOutput, storyId: string) => {
    return await upsertSyllabusToDb(syllabus, storyId);
  };

  const deleteSyllabus = async (subjectId: string) => {
    return await deleteSyllabusFromDb(subjectId);
  };

  return {
    getSyllabus,
    getSyllabusFromStoryId,
    upsertSyllabus,
    deleteSyllabus,
  };
};

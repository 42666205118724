import { formatRelative, parseISO } from "date-fns";
import { enUS, fr } from "date-fns/locale";
import { LOCALE_ENGLISH, LOCALE_FRENCH } from "../constants/constant.ts";

export function formatDateString(dateString: string | null, locale: string): string {
  if (!dateString) return "";
  try {
    const parsedDate = parseISO(dateString);

    let localeSetting;
    switch (locale) {
      case LOCALE_FRENCH:
        localeSetting = fr;
        break;
      case LOCALE_ENGLISH:
        localeSetting = enUS;
        break;
      default:
        throw new Error(`Unsupported locale: ${locale}`);
    }
    return formatRelative(parsedDate, new Date(), { locale: localeSetting });
  } catch (error) {
    return dateString;
  }
}

export function getYear(dateString: string): number {
  const parsedDate = parseISO(dateString);
  return parsedDate.getFullYear();
}

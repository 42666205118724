import { DragEvent, useCallback, useEffect, useState } from "react";
import { useEdgesState, useNodesState, useReactFlow } from "@xyflow/react";
import { useConnectionHandling } from "@/components/admin/storyGraph/layout/useConnectionHandling";
import { useFlowLayout } from "@/components/admin/storyGraph/layout/useFlowLayout";
import { Tables } from "@/types/database";
import {
  MOMENT_NODE_TYPES,
  MomentEdge,
  MomentNode,
  MomentType,
  SceneNode,
} from "@/components/admin/storyGraph/common/types";
import {
  createEmptyMomentEdge,
  createEmptyMomentNode,
  createMomentEdges,
  createMomentNodes,
} from "@/components/admin/storyGraph/momentLevel/MomentNodeEdgeFactories.ts";

interface MomentFlowProps {
  moments: Tables<"blueprint_moments">[];
  transitions: Tables<"blueprint_moment_transitions">[];
  selectedSceneNode: SceneNode | null;
  scenes: Tables<"blueprint_scenes">[];
  beatsheets: Tables<"blueprint_beatsheets">[];
  beats: Tables<"blueprint_beats">[];
  branches: Tables<"blueprint_branches">[];
}

export function useMomentFlow({
  moments,
  transitions,
  selectedSceneNode,
  scenes,
  beatsheets,
  beats,
  branches,
}: MomentFlowProps) {
  const [momentNodes, setMomentNodes, onMomentNodesChange] = useNodesState<MomentNode>([]);
  const [momentEdges, setMomentEdges, onMomentEdgesChange] = useEdgesState<MomentEdge>([]);
  const [selectedMomentNode, setSelectedMomentNode] = useState<MomentNode | null>(null);

  const { performLayout } = useFlowLayout();
  const { screenToFlowPosition } = useReactFlow();

  // Create moment nodes when a scene is selected
  useEffect(() => {
    if (!selectedSceneNode) return;

    // Filter moments for the selected scene
    const sceneMoments = moments.filter(
      (moment) => moment.scene_id === selectedSceneNode.data.scene?.id,
    );

    // Create nodes for the filtered moments
    const initialMomentNodes = createMomentNodes(sceneMoments, scenes, beatsheets, beats, branches);

    // Filter transitions to only include those between moments in this scene
    const sceneTransitions = transitions.filter(
      (transition) =>
        sceneMoments.some((moment) => moment.id === transition.current_moment_id) &&
        sceneMoments.some((moment) => moment.id === transition.next_moment_id),
    );

    const initialMomentEdges = createMomentEdges(sceneTransitions, sceneMoments);
    const layoutedMomentNodes = performLayout(initialMomentNodes, initialMomentEdges);

    setMomentNodes(layoutedMomentNodes);
    setMomentEdges(initialMomentEdges);
  }, [selectedSceneNode, moments, transitions, scenes, beatsheets, beats, branches]);

  // Moment level connection handling
  const {
    onConnect: onMomentConnect,
    findNodeAtPosition: findMomentAtPosition,
    handleNodeInsertion: handleMomentInsertion,
  } = useConnectionHandling({
    nodes: momentNodes,
    edges: momentEdges,
    setEdges: setMomentEdges,
    createEmptyEdge: (source: string, target: string) =>
      createEmptyMomentEdge({
        id: `e${source}-${target}`,
        source,
        target,
        fromMoment: null,
        toMoment: null,
      }),
  });

  const onMomentsDrop = useCallback(
    (event: DragEvent<HTMLDivElement>) => {
      event.preventDefault();

      // Only allow moment drops if a scene is selected
      if (!selectedSceneNode?.data.scene?.id) {
        console.warn("Cannot add moments without a selected scene");
        return;
      }

      const type = event.dataTransfer.getData("application/reactflow") as MomentType;
      if (!Object.keys(MOMENT_NODE_TYPES).includes(type)) return;

      const position = screenToFlowPosition({
        x: event.clientX,
        y: event.clientY,
      });

      const newNodeId = `${type}-${momentNodes.length + 1}`;

      const newNode = createEmptyMomentNode({
        id: newNodeId,
        type,
        position,
        beatsheet: selectedSceneNode.data.beatsheet,
        beat: selectedSceneNode.data.beat,
        branch: selectedSceneNode.data.branch,
        scene: selectedSceneNode.data.scene,
      });

      const targetNode = findMomentAtPosition(position);
      const newEdges = handleMomentInsertion(targetNode, newNode.id);
      const updatedNodes = [...momentNodes, newNode];
      const layoutedNodes = performLayout(updatedNodes, newEdges) as MomentNode[];

      setMomentNodes(layoutedNodes);
      setMomentEdges(newEdges as MomentEdge[]);
    },
    [
      momentNodes,
      screenToFlowPosition,
      findMomentAtPosition,
      handleMomentInsertion,
      performLayout,
      selectedSceneNode,
    ],
  );

  const onMomentsLayout = useCallback(() => {
    const layoutedNodes = performLayout(momentNodes, momentEdges);
    setMomentNodes(layoutedNodes);
  }, [momentNodes, momentEdges, performLayout]);

  const updateMomentNode = useCallback(
    (nodeId: string, newData: Partial<MomentNode>) => {
      setMomentNodes((nds) =>
        nds.map((node) => (node.id === nodeId ? ({ ...node, ...newData } as MomentNode) : node)),
      );
    },
    [setMomentNodes],
  );

  return {
    momentNodes,
    momentEdges,
    selectedMomentNode,
    setSelectedMomentNode,
    onMomentNodesChange,
    onMomentEdgesChange,
    onMomentConnect,
    onMomentsDrop,
    onMomentsLayout,
    updateMomentNode,
  };
}
